// import { FileOutline } from 'antd-mobile-icons'
import "./index.less";

// 标题和描述
const TipsBox = ({ title = '', desc = '' }: any) => {
  return (
    <div className='tipsBoxContent'>
      <div className='title'>{title}</div>
      <div className='desc'>{desc}</div>
    </div>
  );
};
export default TipsBox;
