import { useState, useEffect } from "react";
import Masonry from 'react-responsive-masonry';
import { Tabs, Image } from 'antd-mobile';
import { isArray } from 'lodash-es';
import TipsBox from '@/components/TipsBox';
import { getMonoLayerList } from '@/utils';
import { PHOTOS_TYPE } from '../../constants';
import imageLoad from '@/assets/images/imageLoad.png';

import "./index.less";

// 选择照片
const SelectPhotos = ({ handleClick, handleSetSelectedImageA }: any) => {
  const [photosList, setPhotosList] = useState<any>([]);

  useEffect(() => {
    setPhotosList(getMonoLayerList(PHOTOS_TYPE, 'hot_photos_list', '.png'));
  }, []);
  console.log('photosList', photosList);

  return (
    <div className="selectPhotosContent">
      <TipsBox title='从相册选择照片' desc='' />
      <a className='entryBtn' onClick={handleClick}>选择照片</a>
      <TipsBox title='or' desc='' />
      <TipsBox title='从以下图片模版选择照片' desc='' />
      {isArray(photosList) && photosList.length > 0 &&
        <Tabs defaultActiveKey='1' style={{ background: '#FFFFFF' }}>
          {photosList.map((item, index) =>
            <Tabs.Tab title={item.name} key={index + 1}>
              <Masonry columnsCount={2} gutter={10} className='photosList'>
                {isArray(item.photos) && item.photos.length > 0 && item.photos.map((cItem, cIndex) =>
                  <Image src={cItem} className="item" key={cIndex} onClick={() => handleSetSelectedImageA(cItem)} fallback={imageLoad} />
                )}
              </Masonry>
            </Tabs.Tab>
          )}
        </Tabs>
      }

    </div >
  );
};
export default SelectPhotos;
