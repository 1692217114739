import React, { useRef, ChangeEvent } from 'react';

interface ImageUploaderProps {
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
}


const Upload: React.FC<ImageUploaderProps> = ({ setSelectedImage }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
    } else {
      alert('Please select an image file!');
    }
  };

  return (
    <div>
      <a className='entryBtn' onClick={handleClick}>
        选择图片
      </a>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </div>
  );
};

export default Upload;