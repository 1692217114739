import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { isString } from 'lodash-es';
import "./index.less";

const VideoModule = ({ isPlay, img, video, closePlay }: any) => {
  const [isOpen, setOpen] = useState(false);
  const closeVideo = () => {
    setOpen(false);
    closePlay(false);
  };

  useEffect(() => {
    if (isPlay && isString(img) && img !== '' && isString(video) && video !== '') {
      setOpen(isPlay)
    }
  }, [isPlay]);

  return (
    <>
      {isOpen &&
        <div className='videoModuleContent'>
          <div className='make' onClick={closeVideo}></div>
          <div className="playerContent">
            <ReactPlayer
              url={video}
              playing
            />
          </div>
        </div>
      }
    </>
  );
};
export default VideoModule;
