import { useState, useRef, ChangeEvent } from "react";
import { isString } from 'lodash-es';
import TipsBox from '@/components/TipsBox';
import ArrowPicture from '@/components/ArrowPicture';
import 去除背景_前 from '@/assets/images/一级/图片工具_去除背景_前.png';
import 去除背景_后 from '@/assets/images/一级/图片工具_去除背景_后.png';
import PayComponents from '@/components/Pay';
import "./index.less";

// 去除背景
const Background = () => {
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
    } else {
      alert('Please select an image file!');
    }
  };

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }

  return (
    <div className="backgroundContent">
      <TipsBox title='去除背景' desc='一键去除主体以外的画面' />
      <ArrowPicture imgs={[去除背景_前, 去除背景_后]} setSelectedImage />
      <a className='entryBtn' onClick={handleClick}>上传图片</a>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/*"
      />

      {selectedImage && isString(selectedImage.name) && selectedImage.name !== '' &&
        <div className='footer'>
          <div className='originalPicture'>
            <img src={URL.createObjectURL(selectedImage)} />
          </div>
          <PayComponents price='支付￥1元(会员￥0.5元)' desc='付费提交生成后，请等待10~20秒，在最上面橙色画框中等待最终3张成品图片，选择效果最好的下载保存' callbackPay={callbackPay} />
        </div>
      }

    </div>
  );
};
export default Background;
