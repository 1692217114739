import { useState, useRef, ChangeEvent } from "react";
import { isString } from 'lodash-es';
import { AddCircleOutline } from 'antd-mobile-icons'
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import PayComponents from '@/components/Pay';
import SelectPhotos from "./components/SelectPhotos";
import AI写真 from '@/assets/images/一级/热门娱乐_AI写真.png';
import AI写真_前 from '@/assets/images/二级/热门娱乐_AI写真_前.png';
import AI写真_后 from '@/assets/images/二级/热门娱乐_AI写真_后.png';
// import AI写真_成品图 from '@/assets/images/二级/热门娱乐_AI写真_成品图.png';
import "./index.less";

// AI写真
const AIFace = () => {
  const [selectedImageA, setSelectedImageA] = useState<string>(null);
  const [selectedImageB, setSelectedImageB] = useState<string>(null);
  const hiddenFileInputA = useRef<HTMLInputElement>(null);
  const hiddenFileInputB = useRef<HTMLInputElement>(null);
  const [isSelectPhotos, setIsSelectPhotos] = useState<boolean>(false);

  const handleImageUploadA = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImageA(URL.createObjectURL(file));
      event.target.value = null;
      setIsSelectPhotos(false);
    } else {
      alert('请选择照片!');
    }
  };
  const handleImageUploadB = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImageB(URL.createObjectURL(file));
      event.target.value = null;
    } else {
      alert('请选择照片!');
    }
  };

  const handleSelectUptade = () => {
    hiddenFileInputA.current?.click();
  }

  const handleSetSelectedImageA = (img) => {
    setSelectedImageA(img);
    setIsSelectPhotos(false);
  }

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }

  return (
    <div className="aiFaceContent">
      {isSelectPhotos ?
        <SelectPhotos handleClick={handleSelectUptade} handleSetSelectedImageA={handleSetSelectedImageA} />
        :
        <>
          <TipsBox title='AI写真' desc='上传照片生成4张自选风格写真' />
          <ImageModule imgs={AI写真} />
          <div className="contrast">
            <div className="imgList">
              <div className="itemContent" onClick={() => setIsSelectPhotos(true)}>
                <img className="itemImage" src={selectedImageA ? selectedImageA : AI写真_前} />
                <AddCircleOutline className="itemBefore" />
              </div>
              <div className="itemContent" onClick={() => hiddenFileInputB.current?.click()} >
                <img className="itemImage" src={selectedImageB ? selectedImageB : AI写真_后} />
                <AddCircleOutline className="itemBefore" />
              </div>
            </div>
            <div className="descList">
              <div className="text">点击上方+号，选择模版图片</div>
              <div className="text">点击上方+号，上传肖像图片</div>
            </div>
          </div>

          <div className="pictureFaceinsContent">
            <h1>使用说明：</h1>
            <div className="center">
              <ul>
                <li>上传图片仅支持JPG/PNG/WEBP等格式;</li>
                <li>上传图片要求近景或特写，头部完整、面部清洗、五官无遮挡或较少遮挡、未闭眼;</li>
                <li>上传图片大小不能超过5M</li>
              </ul>
            </div>
          </div>
          {selectedImageA && isString(selectedImageA) && selectedImageA !== '' && selectedImageB && isString(selectedImageB) && selectedImageB !== '' &&
            <div className='footer'>
              <PayComponents price='支付￥4元(会员￥2元)' desc='付费提交生成后，请等待20~30秒，在最上面橙色画框中等待最终成品图片，可直接下载保存' callbackPay={callbackPay} />
            </div>
          }
        </>
      }

      <input
        type="file"
        ref={hiddenFileInputA}
        onChange={handleImageUploadA}
        style={{ display: 'none' }}
        accept="image/*"
      />
      <input
        type="file"
        ref={hiddenFileInputB}
        onChange={handleImageUploadB}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </div>
  );
};
export default AIFace;
