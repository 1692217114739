// 风格模板
export const STYLE_TEMPLATE_TYPE = [
  {
    type: '风格模板',
    images: [{
      name: '写实',
    }, {
      name: '商业',
    }, {
      name: '胶片',
    }, {
      name: '黏土',
    }, {
      name: '动漫',
    }, {
      name: '水彩',
    }, {
      name: '3D',
    }, {
      name: '剪纸',
    }, {
      name: '水墨',
    }, {
      name: '海报',
    }, {
      name: '素描',
    }, {
      name: '古典',
    }, {
      name: '印象',
    }, {
      name: '贴纸',
    }, {
      name: '漫画',
    }, {
      name: '像素',
    }, {
      name: '线条',
    }],
  },
];

// 场景模板
export const SCENE_TEMPLATE_TYPE = [
  {
    type: '场景模板',
    images: [{
      name: '梦幻公主',
    }, {
      name: '魅力女神',
    }, {
      name: '艾露莎',
    }, {
      name: '布尔玛',
    }, {
      name: '别致',
    }, {
      name: '精致',
    }, {
      name: '好莱坞',
    }, {
      name: '神代利世',
    }, {
      name: '戏剧',
    }, {
      name: '奢华女',
    }, {
      name: '印花',
    }, {
      name: '针织',
    }, {
      name: '艺术',
    }, {
      name: '朋克女',
    }, {
      name: '妮可罗宾',
    }, {
      name: '娜美',
    }, {
      name: '春野樱',
    }, {
      name: '丽日御茶子',
    }, {
      name: '赛博朋克',
    }, {
      name: '前卫',
    }, {
      name: '摇滚',
    }, {
      name: '城市嘻哈',
    }, {
      name: '里约狂欢',
    }, {
      name: '魅力',
    }, {
      name: '蒸汽朋克',
    }, {
      name: '中东风情',
    }, {
      name: '朋克',
    }, {
      name: '复古霓虹',
    }, {
      name: '波西米亚',
    }, {
      name: '岛屿天堂',
    }, {
      name: '地中海',
    }, {
      name: '东方意象',
    }, {
      name: '化装舞会',
    }, {
      name: '威尼斯狂欢',
    }, {
      name: '舞台摇滚',
    }, {
      name: '豪华',
    }, {
      name: '悟空',
    }, {
      name: '惊奇队长',
    }, {
      name: '雷神',
    }, {
      name: '钢铁侠',
    }, {
      name: '奇异博士',
    }, {
      name: '美国队长',
    }, {
      name: '死侍',
    }, {
      name: '黑豹',
    }, {
      name: '蜘蛛侠',
    }, {
      name: '超级英雄',
    }, {
      name: '蚁人',
    }, {
      name: '超人',
    }, {
      name: '蝙蝠侠',
    }, {
      name: '海王',
    }, {
      name: '特兰克斯',
    }, {
      name: '贝吉塔',
    }, {
      name: '短笛',
    }, {
      name: '路飞',
    }, {
      name: '银河外交官',
    }, {
      name: '绝地武士',
    }, {
      name: '罗马军团',
    }, {
      name: '鸠卡卡西',
    }, {
      name: '复仇之魂',
    }, {
      name: '维京海盗',
    }, {
      name: '加勒比海盗',
    }, {
      name: '美洲酋长',
    }, {
      name: '中古骑士',
    }, {
      name: '天方夜谭',
    }, {
      name: '孔雀',
    }, {
      name: '珊瑚',
    }, {
      name: '使馆',
    }, {
      name: '中国龙',
    }, {
      name: '电竞',
    }],
  },
]