import { useState, useRef, ChangeEvent, useEffect } from "react";
import { Swiper, Image } from 'antd-mobile';
import { isString, isArray } from 'lodash-es';
import { AddCircleOutline } from 'antd-mobile-icons'
import TipsBox from '@/components/TipsBox';
import PayComponents from '@/components/Pay';
import { STYLE_TEMPLATE_TYPE, SCENE_TEMPLATE_TYPE } from './constants';
import { getMultiLayerList, exportchunkArray } from '@/utils';
import 个性头像 from '@/assets/images/二级/热门娱乐_个性头像.png';
import "./index.less";

// 个性头像
const AvatarFace = () => {
  // 风格模板
  const [styleTemplateList, setStyleTemplateList] = useState<any>([]);
  // 场景模板
  const [sceneTemplateList, setSceneTemplateList] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<string>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  // 选中模板
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [templateImageUrl, setTemplateImageUrl] = useState<string>('');

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(URL.createObjectURL(file));
      event.target.value = null;
    } else {
      alert('请选择照片!');
    }
  };

  // 选择图片
  const handleSelectImage = (param) => {
    const { url, name } = param;
    setSelectedTemplate(name);
    setTemplateImageUrl(url);
  }

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }

  useEffect(() => {
    const styleData = getMultiLayerList(STYLE_TEMPLATE_TYPE, 'hot_style_template_list', '.webp');
    if (isArray(styleData) && styleData.length > 0) {
      setStyleTemplateList(exportchunkArray(styleData[0]?.photos, 6));
    }
    const sceneData = getMultiLayerList(SCENE_TEMPLATE_TYPE, 'hot_scene_template_list', '.webp');
    if (isArray(sceneData) && sceneData.length > 0) {
      setSceneTemplateList(exportchunkArray(sceneData[0]?.photos, 9));
    }
  }, []);

  console.log('styleTemplateList', templateImageUrl);

  return (
    <div className="avatarFaceContent">
      <TipsBox title='个性头像' desc='上传照片生成4张自选风格写真' />
      <div className="contrast">
        <div className="imgList">
          <div className="itemContent" onClick={() => hiddenFileInput.current?.click()}>
            <img className="itemImage" src={selectedImage ? selectedImage : 个性头像} />
            <AddCircleOutline className="itemBefore" />
          </div>
          <div className="itemText">
            <h3>① 上传肖像图片</h3>
            <div>点击+号上传照片</div>
            <div>1、上传图片仅支持JPG/PNG/WEBP格式;</div>
            <div>2、上传图片要求近景或特写，头部完整、面部清洗、五官无遮挡或较少遮挡、未闭眼;</div>
            <div>3、图片大小不能超过5M;</div>
          </div>
        </div>
      </div>

      <div className="styleBox">
        <div className="itemText">
          <h3>② 选择风格模板</h3>
        </div>
        <Swiper className="swiperStyleList">
          {isArray(styleTemplateList) && styleTemplateList.length > 0 && styleTemplateList.map((item, index) =>
            <Swiper.Item key={index} className="swiperItem">
              {isArray(item) && item.length > 0 && item.map((cItem, cIndex) =>
                <div key={cIndex} className="item" onClick={() => handleSelectImage(cItem)}>
                  <Image src={cItem.url} className="imgItem" style={{ borderColor: selectedTemplate === cItem.name ? '#FF9100' : '#FFFFFF' }} />
                  <div className="cItemText">{cItem.name}</div>
                </div>
              )}
            </Swiper.Item>
          )}
        </Swiper>
      </div>

      <div className="sceneBox">
        <div className="itemText">
          <h3>③ 选择场景模板</h3>
        </div>
        <Swiper className="swiperSceneList">
          {isArray(sceneTemplateList) && sceneTemplateList.length > 0 && sceneTemplateList.map((item, index) =>
            <Swiper.Item key={index} className="swiperItem">
              {isArray(item) && item.length > 0 && item.map((cItem, cIndex) =>
                <div key={cIndex} className="item" onClick={() => handleSelectImage(cItem)}>
                  <Image src={cItem.url} className="imgItem" style={{ borderColor: selectedTemplate === cItem.name ? '#FF9100' : '#FFFFFF' }} />
                  <div className="cItemText">{cItem.name}</div>
                </div>
              )}
            </Swiper.Item>
          )}
        </Swiper>
      </div>

      <div className="pictureFaceinsContent">
        <div className="center">
          付费提交生成后，请等待约30~40秒，在上面橙色画框中等待3张成品图片，选择效果满意的1~3张下载保存。
        </div>
      </div>
      {
        selectedImage && isString(selectedImage) && selectedImage !== '' && selectedImage && isString(templateImageUrl) && templateImageUrl !== '' &&
        <div className='footer'>
          <PayComponents price='支付￥4元(会员￥2元)' desc='付费提交生成后，请等待20~30秒，在最上面橙色画框中等待最终成品图片，可直接下载保存' callbackPay={callbackPay} />
        </div>
      }

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </div >
  );
};
export default AvatarFace;

