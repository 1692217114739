import { useState, useRef, ChangeEvent } from "react";
import { isString } from 'lodash-es';
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import PayComponents from '@/components/Pay';
import "./index.less";

// 图生文
const ImageToText = () => {
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
    } else {
      alert('Please select an image file!');
    }
  };

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }
  // console.log(URL.createObjectURL(selectedImage));

  return (
    <div className="imageToTextContent">
      <TipsBox title='图像识别' desc='智能识别上传图片的文字/图像内容' />
      <a className='entryBtn' onClick={handleClick}>上传图片</a>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/*"
      />

      {selectedImage && isString(selectedImage.name) && selectedImage.name !== '' &&
        <div className='footer'>
          <ImageModule imgs={URL.createObjectURL(selectedImage)} />
          <div className='originalText'>
            这张图片展示了一辆金色的大型卡车，它似乎正在公路上行驶。这辆卡车的设计非常豪华和独特，带有“ROLLS ROYCE”的标志，暗示了其品牌身份。车辆的前部有明显的散热格栅和车头灯，侧面则装饰着一些细节元素。整个场景被渲染得相当逼真，包括卡车在阳光下的反光效果以及周围环境的模糊动态感，给人一种运动中的感觉。背景中还有树木和其他道路元素，增加了图像的真实感。
          </div>
          <PayComponents price='支付￥0.5元(会员￥0元)' desc='付费提交识别后，请等待20~30秒，在上面橙色画框中等待识别结果，可复制文字信息' callbackPay={callbackPay} />
        </div>
      }

    </div>
  );
};
export default ImageToText;
