// import { useState, useEffect } from "react";
// import wx from "weixin-js-sdk";
import useStore from '@/store';
import { Tabs } from 'antd-mobile'
import Header from '@/components/Header';
import { DRAWING_KEY, HOT_KEY, CUSTOMIZ_KEY } from '@/constants';
import Drawing from './components/Drawing';
import Hot from './components/Hot';
import Customiz from './components/Customiz';
import "./index.less";

// 工作台
const Work = () => {
  const tabsValue = useStore((state) => state.tabsValue);
  const changeUseTabsTabsValue = useStore((state) => state.changeUseTabsTabsValue);
  return (
    <div className='workContent'>
      {false &&
        <Header />
      }
      <Tabs className='workTabs' activeKey={tabsValue} onChange={(e) => changeUseTabsTabsValue(e)}>
        <Tabs.Tab title='图片工具' key={DRAWING_KEY}>
          <Drawing />
        </Tabs.Tab>
        <Tabs.Tab title='热门娱乐' key={HOT_KEY}>
          <Hot />
        </Tabs.Tab>
        <Tabs.Tab title='个性定制' key={CUSTOMIZ_KEY}>
          <Customiz />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};
export default Work;
