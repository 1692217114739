import { APPID, EXPIRATION_TIME, STATIC_PATH } from '@/constants';

// 获取code重定向
export const getCodeRedirect = (url: string) => {
  const redirect_uri = encodeURIComponent(url);
  const state = 'state';
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
};

// 设置openId和过期时间
export const saveToLocalStorage = (key, value) => {
  const now = new Date().getTime(); // 获取当前时间戳
  const item = {
    value,
    timestamp: now,
  };
  window.localStorage.setItem(key, JSON.stringify(item)); // 将对象转换成字符串存储
}

// 获取openId&是否过期，如果过期则清除
export const getFromLocalStorage = (key) => {
  const itemStr = window.localStorage.getItem(key);
  // 检查值是否存在
  if (!itemStr) return null;
  try {
    const item = JSON.parse(itemStr);
    const now = new Date().getTime();

    // 检查是否超过EXPIRATION_TIME(天)
    if (now - item.timestamp > EXPIRATION_TIME * 24 * 60 * 60 * 1000) {
      window.localStorage.clear(); // 超过7天则清除
      return null;
    }

    return item.value;
  } catch (error) {
    window.localStorage.clear();
  }
}

export const isWeixin = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1 && ua.indexOf('wxwork') === -1;
}

export const getMonoLayerList = (data, fileName, fileType) => {
  const URL_DOMAIN = `${STATIC_PATH}images/${fileName}`;
  return data.reduce((acc, item) => {
    // 为每个分类生成图片链接数组
    const links = Array.from({ length: item.num }, (_, i) =>
      `${URL_DOMAIN}/${item.name}/${String(i + 1).padStart(2, '0')}${fileType}`
    );
    // 将当前分类的图片链接数组添加到累加器中
    acc.push({ name: item.name, photos: links });
    return acc;
  }, []); // 初始化累加器为一个空数组
};

export const getMultiLayerList = (data, fileName, fileType) => {
  const URL_DOMAIN = `${STATIC_PATH}images/${fileName}`;
  return data.reduce((acc, item) => {
    const links = item.images.map((image, i) => ({
      url: `${URL_DOMAIN}/${item.type}/${String(i + 1).padStart(2, '0')}${fileType}`,
      name: image.name
    }));
    acc.push({ type: item.type, photos: links });
    return acc;
  }, []);
};

// 分割组
export const exportchunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}
