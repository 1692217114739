export const APPID = 'wxce6fa0d6e81b1a21';
export const DOMAIN_NAME = 'https://www.chouxiu.net/';
export const STATIC_PATH = 'https://chouxiu.net/static/';
// 过期时间(天)
export const EXPIRATION_TIME = 100;
// 日期转换
export const DATE_FORMAT_SECOND = 'YYYY-MM-DD HH:mm:ss';

export const DRAWING_KEY = 'DRAWING_KEY';
export const HOT_KEY = 'HOT_KEY';
export const CUSTOMIZ_KEY = 'CUSTOMIZ_KEY';


