import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Slider } from 'antd-mobile';
import './index.less';

interface DaubToolProps {
  selectedImage: any;
  setOverflow: (e: string) => void;
}

interface DaubToolHandle {
  downloadDaubedImage: (e: any) => string;
}

const DaubTool = forwardRef<DaubToolHandle, DaubToolProps>(({ selectedImage, setOverflow }, ref) => {
  const [zoomLevel, setZoomLevel] = useState(50);
  const toastValue = (value: number) => {
    setZoomLevel(value);
  }

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayRef = useRef<HTMLCanvasElement>(null);
  const [isDaubing, setIsDaubing] = useState(false);
  const history = useRef<string[]>([]);

  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const overlayCtx = useRef<CanvasRenderingContext2D | null>(null);

  const originalImageSize = useRef({ width: 0, height: 0 });
  
  useEffect(() => {
    if (selectedImage) {
      const canvas = canvasRef.current;
      const overlay = overlayRef.current;
      if (!canvas || !overlay) return;
      const context = canvas.getContext('2d');
      const overlayContext = overlay.getContext('2d');
      if (!context || !overlayContext) return;
  
      ctx.current = context;
      overlayCtx.current = overlayContext;
  
      // 设置画布样式
      overlayContext.lineWidth = canvas.width * 0.05;
      overlayContext.lineCap = 'round';
      overlayContext.strokeStyle = '#fff';
  
      // 绘制背景图片
      const background = new Image();
      background.crossOrigin = 'anonymous';
      background.src = URL.createObjectURL(selectedImage);
      background.onload = () => {
        originalImageSize.current.width = background.width;
        originalImageSize.current.height = background.height;
  
        const imgRatio = background.width / background.height;
        const maxWidth = window.innerWidth - 40;
        const maxHeight = window.innerHeight - 40;
  
        if (maxWidth / imgRatio < maxHeight) {
          canvas.width = maxWidth;
          canvas.height = maxWidth / imgRatio;
        } else {
          canvas.height = maxHeight;
          canvas.width = maxHeight * imgRatio;
        }
  
        overlay.width = canvas.width;
        overlay.height = canvas.height;
        overlayContext.strokeStyle = '#fff';
        // 重新设置画笔的粗细
        overlayContext.lineWidth = canvas.width * 0.05;
        context.drawImage(background, 0, 0, canvas.width, canvas.height);
      };
  
      background.onerror = (error) => {
        console.error('Failed to load image:', error);
      };
    }
  }, [selectedImage]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const overlay = overlayRef.current;
    if (canvas && overlay) {
      canvas.style.transform = `scale(${zoomLevel / 50})`;
      overlay.style.transform = `scale(${zoomLevel / 50})`;
    }
  }, [zoomLevel]);

  // 开始画
  const startPosition = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.TouchEvent<HTMLCanvasElement>) => {
    setOverflow('hidden');
    setIsDaubing(true);
    const rect = overlayRef.current?.getBoundingClientRect();
    const x = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const y = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const scaleX = (overlayRef.current?.width || 0) / (rect?.width || 1);
    const scaleY = (overlayRef.current?.height || 0) / (rect?.height || 1);
    if (overlayCtx.current) {
      overlayCtx.current.beginPath();
      overlayCtx.current.moveTo((x - (rect?.left || 0)) * scaleX, (y - (rect?.top || 0)) * scaleY);

      // 在开始绘制时保存状态
      history.current.push(overlayRef.current?.toDataURL());
    }
  };

  const finishedPosition = () => {
    setIsDaubing(false);
    if (overlayCtx.current) {
      overlayCtx.current.beginPath();
    }
    setOverflow('auto');
  };

  // 写入
  const draw = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDaubing || !overlayCtx.current) return;

    const rect = overlayRef.current?.getBoundingClientRect();
    const x = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const y = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const scaleX = (overlayRef.current?.width || 0) / (rect?.width || 1);
    const scaleY = (overlayRef.current?.height || 0) / (rect?.height || 1);
    overlayCtx.current.lineTo((x - (rect?.left || 0)) * scaleX, (y - (rect?.top || 0)) * scaleY);
    overlayCtx.current.stroke();
  };

  const downloadDaubedImage = (callback) => {
    const canvas = canvasRef.current;
    const overlay = overlayRef.current;
    if (!canvas || !overlay) return '';
  
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) return '';
  
    // 使用原图的尺寸
    tempCanvas.width = originalImageSize.current.width;
    tempCanvas.height = originalImageSize.current.height;
  
    // 将背景图绘制到临时画布上
    const background = new Image();
    background.src = URL.createObjectURL(selectedImage);
    background.onload = () => {
      tempCtx.drawImage(background, 0, 0, tempCanvas.width, tempCanvas.height);
  
      // 将轨迹绘制到临时画布上，保持比例
      const scaleX = tempCanvas.width / canvas.width;
      const scaleY = tempCanvas.height / canvas.height;
      const overlayImage = new Image();
      overlayImage.src = overlay.toDataURL();
      overlayImage.onload = () => {
        tempCtx.globalCompositeOperation = 'destination-in';
        tempCtx.drawImage(overlayImage, 0, 0, overlay.width * scaleX, overlay.height * scaleY);
  
        const dataURL = tempCanvas.toDataURL('image/png');
        console.log('dataURL', dataURL);
  
        callback(dataURL);
      };
    };
  };

  // 清空
  const clearDaub = () => {
    const overlay = overlayRef.current;
    const context = overlayCtx.current;
    if (!overlay || !context) return;

    // 清除所有内容
    context.clearRect(0, 0, overlay.width, overlay.height);
  };

  // 撤销
  const undo = () => {
    // 从历史堆栈中弹出最近的状态
    const lastState = history.current.pop();

    if (!lastState || !overlayCtx.current) return;

    // 创建一个新的Image对象，并设置其src为最近的状态
    const img = new Image();
    img.src = lastState;
    img.onload = () => {
      // 当图像加载完成时，将canvas恢复到该状态
      overlayCtx.current?.clearRect(0, 0, overlayRef.current?.width || 0, overlayRef.current?.height || 0);
      overlayCtx.current?.drawImage(img, 0, 0);
    };
  };

  useImperativeHandle(ref, () => ({
    downloadDaubedImage,
  }));

  return (
    <>
      {selectedImage &&
        <div className="drawContent">
          <div className='canvasframe'>
            <canvas
              className='drawCanvas'
              ref={canvasRef}
              width={620}
            ></canvas>
            <canvas
              className='overlayCanvas'
              ref={overlayRef}
              width={620}
              onMouseDown={startPosition}
              onMouseUp={finishedPosition}
              onMouseOut={finishedPosition}
              onMouseMove={draw}
              onTouchStart={startPosition}
              onTouchEnd={finishedPosition}
              onTouchCancel={finishedPosition}
              onTouchMove={draw}
            ></canvas>
          </div>

          <div className='toolbar'>
            <div className='brushSize'>
              <div>画笔尺寸:</div>
              <Slider
                className='sliderBar'
                defaultValue={50}
                onAfterChange={toastValue}
              />
              <span>{zoomLevel}%</span>
            </div>
            <a className='undo' onClick={undo}>撤销</a>
            <a className='clear' onClick={clearDaub}>清空涂抹</a>
          </div>
        </div>
      }
    </>
  );
});

export default DaubTool;