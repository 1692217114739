import { Outlet } from 'react-router-dom';
import ParticleBackground from '@/components/ParticleBackground';
import Login from '@/components/Login';

const App = () => (
  <>
    <ParticleBackground />
    <Outlet />
    {process.env.NODE_ENV !== 'development' && <Login />}
  </>
);

export default App;