import useStore from '@/store';
import bussinessRequest from "@/server/request";
import { isString } from "lodash-es";
import { useAsyncEffect } from "ahooks";
import { DOMAIN_NAME } from '@/constants';
import { getCodeRedirect, saveToLocalStorage, getFromLocalStorage } from '@/utils';
import "./index.less";

interface ResponseOpenId {
  openId: string;
  nickname: string;
  headImgUrl?: string;
}

interface ResponseData {
  success: boolean;
  memberStatus?: number | undefined;
  deadLine?: string;
  desc?: string;
}

// 全局登录
const Login = () => {
  const userInfo = useStore((state) => state.userInfo);
  const changeSetUserInfo = useStore((state) => state.changeSetUserInfo);
  // 获取openid
  const getOpenId = async (code) => {
    const [err, data] = await bussinessRequest<ResponseOpenId>({
      url: "/api/wechat/login",
      method: "POST",
      data: { code },
    });
    if (!err && data) {
      const { openId = "", nickname = "", headImgUrl = "" } = data || {};
      if (openId && nickname) {
        saveToLocalStorage('authentication', openId)
        window.localStorage.setItem("nickname", nickname);
        window.localStorage.setItem("headImgUrl", headImgUrl);
        const url = window.localStorage.getItem('DOMAIN') || DOMAIN_NAME;
        window.location.href = url;
      }
    }
  };

  // 获取会员信息
  const getDesc = async (openId) => {
    const [err, data] = await bussinessRequest<ResponseData>({
      url: `/api/member?openId=${openId}`,
      method: "GET",
    });
    const { memberStatus = 0, deadLine = "", desc = "", } = data || {};
    if (!err && data) {
      const authentication = getFromLocalStorage('authentication');
      const nickname = window.localStorage.getItem("nickname") || "";
      const headImgUrl = window.localStorage.getItem("headImgUrl") || null;
      changeSetUserInfo({
        openId: authentication,
        nickname,
        headImgUrl,
        memberStatus,
        deadLine,
        desc,
      });
    }
  };

  // 初始化
  useAsyncEffect(async () => {
    try {
      // 判断是否已经在本地存有认证
      const authentication = getFromLocalStorage('authentication');
      const nickname = window.localStorage.getItem("nickname") || "";
      const headImgUrl = window.localStorage.getItem("headImgUrl") || null;
      if (isString(authentication) && authentication !== "") {
        changeSetUserInfo({
          openId: authentication,
          nickname,
          headImgUrl,
        });
        getDesc(authentication);
        return;
      }

      // 如果没认证则获取url的code，没有code则重定向登录
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("code") && urlParams.has("state")) {
        const code = urlParams.get("code");
        if (isString(code) && code !== "") getOpenId(code);
      } else {
        const url = window.location.href;
        window.localStorage.setItem('DOMAIN', url);
        // 去重定向获取code
        getCodeRedirect(url);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  console.log('userInfo', userInfo);
  return (
    <div></div>
  );
};
export default Login;
