// import { useState, useRef } from "react";
import { Button, Space } from 'antd-mobile';
import TipsBox from '@/components/TipsBox';
import "./index.less";

// 支付组件
const PayComponents = ({ price, desc, callbackPay }) => {

  // 开始支付
  const handlePay = () => {
    // 支付完成回调
    callbackPay();
  };

  return (
    <div className="payComponentsContent">
      <TipsBox title='' desc={desc} />
      <Space direction='vertical'>
        <Button color='primary' style={{ width: 370 }} onClick={handlePay}>{price}</Button>
      </Space>
    </div>
  );
};
export default PayComponents;
