import wx from "weixin-js-sdk";
import { useState } from "react";
import { TextArea, Button, Toast, Modal } from "antd-mobile";
import useStore from '@/store';
import bussinessRequest from "../../server/request";
import { isString } from "lodash-es";
// import { useAsyncEffect } from "ahooks";
import {
  STYLE_LIST_OPTIONS,
  SIZE_LIST_OPTIONS,
  PROMPT_WORD_LENGTH,
  NEGATIVE_PROMPT_WORD_LENGTH,
  INSTRUCTIONS,
} from "./constants";
// import { DOMAIN_NAME_GENERATE } from "../../constants";
// import {
//   getCodeRedirect,
//   saveToLocalStorage,
//   getFromLocalStorage,
// } from "../../utils";
import preview01 from "@/assets/images/preview01.png";
import preview02 from "@/assets/images/preview02.png";
import preview03 from "@/assets/images/preview03.png";
import "./index.less";

const previewImages = [preview01, preview02, preview03];

// interface ResponseOpenId {
//   openId?: string;
//   nickname?: string;
//   headImgUrl?: string;
// }

interface ResponseTextToImage {
  taskId?: string;
  time?: string;
  message?: string;
}

const Generator = () => {
  const { userInfo } = useStore();
  const { openId } = userInfo || {};
  const [chooseStyle, setChooseStyle] = useState<number>(1);
  const [sizeStyle, setSizeStyle] = useState<number>(1);
  const [promptWordText, setPromptWordText] = useState<string>("");
  const [negativePromptWordText, setNegativePromptWordText] =
    useState<string>("");
  // const [openId, setOpenId] = useState<string | null>("");

  // 获取openid
  // const getOpenId = async (code) => {
  //   const [err, data] = await bussinessRequest<ResponseOpenId>({
  //     url: "/api/wechat/login",
  //     method: "POST",
  //     data: { code },
  //   });
  //   if (!err && data) {
  //     const { openId = "", nickname = "", headImgUrl = "" } = data || {};
  //     if (openId && nickname) {
  //       saveToLocalStorage("authentication", openId);
  //       window.localStorage.setItem("nickname", nickname);
  //       window.localStorage.setItem("headImgUrl", headImgUrl);
  //       window.location.href = DOMAIN_NAME_GENERATE;
  //     }
  //   }
  // };

  // 提交
  const handlerSubmit = async () => {
    try {
      if (!isString(promptWordText) || promptWordText === "") {
        Toast.show({
          icon: "fail",
          content: "提示词不得为空！",
        });
        return;
      }

      if (isString(openId) && openId !== "") {
        const [err, data] = await bussinessRequest<ResponseTextToImage>({
          url: "/api/ai/tools/textToImage",
          method: "POST",
          data: {
            openId,
            style: chooseStyle,
            sizeNum: sizeStyle,
            prompt: promptWordText,
            negativePrompt: negativePromptWordText,
          },
        });

        const { taskId = "", time = "", message = '任务提交成功，请耐心等待，请返回公众号查看结果' } = data || {};

        if (!err && data) {
          console.log(taskId, time);
          Modal.alert({
            content: message,
            onConfirm: () => {
              WeixinJSBridge.call("closeWindow");
            },
          });
        }
      } else {
        Toast.show({
          icon: "fail",
          content: "未获取到openId...",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // // 初始化
  // useAsyncEffect(async () => {
  //   try {
  //     console.log(wx);
  //     // 判断是否已经在本地存有认证
  //     const authentication = getFromLocalStorage("authentication");
  //     if (isString(authentication) && authentication !== "") {
  //       setOpenId(authentication);
  //       return;
  //     }

  //     // 如果没认证则获取url的code，没有code则重定向登录
  //     const urlParams = new URLSearchParams(window.location.search);
  //     if (urlParams.has("code") && urlParams.has("state")) {
  //       const code = urlParams.get("code");
  //       if (isString(code) && code !== "") getOpenId(code);
  //     } else {
  //       // 去重定向获取code
  //       getCodeRedirect(DOMAIN_NAME_GENERATE);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  console.log('wx', wx);

  return (
    <div className="generateContent">
      <div className="banner">AI工具: 文生图</div>

      {/* 选择图片 */}
      <div className="chooseStyleContent">
        <div className="title">请选择图片风格</div>
        <div className="styleList">
          {STYLE_LIST_OPTIONS.length > 0 &&
            STYLE_LIST_OPTIONS.map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={() => setChooseStyle(item.value)}
              >
                <div
                  className={chooseStyle === item.value ? "check" : "noCheck"}
                >
                  <img src={previewImages[index]} alt={`preview${index}`} />
                </div>
                <div
                  className="label"
                  style={{ color: chooseStyle === item.value ? "#EA5504" : "" }}
                >
                  {item.label}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="lineContent"></div>

      {/* 选择尺寸 */}
      <div className="sizeContent">
        <div className="title">请选择图片尺寸</div>
        <div className="sizeList">
          {SIZE_LIST_OPTIONS.length > 0 &&
            SIZE_LIST_OPTIONS.map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={() => setSizeStyle(item.value)}
              >
                <div className={sizeStyle === item.value ? "check" : "noCheck"}>
                  <div className={`size0${index + 1}`}></div>
                </div>
                <div
                  className="label"
                  style={{ color: sizeStyle === item.value ? "#EA5504" : "" }}
                >
                  {item.label}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="lineContent"></div>

      {/* 提示词 */}
      <div className="promptWordContent">
        <div className="title">
          请在对话框中输入提示词：(字数限制{PROMPT_WORD_LENGTH}字)
        </div>
        <TextArea
          className="promptWordText"
          value={promptWordText}
          placeholder="如：一只金毛狗坐在咖啡馆门口的露天餐桌旁，戴着眼镜 看报纸，耳朵戴着耳机听音乐，餐桌上放着一杯热咖啡冒 着热气"
          rows={5}
          maxLength={PROMPT_WORD_LENGTH}
          showCount
          onChange={(e) => setPromptWordText(e)}
        />
        <div className="title">
          请在对话框中输入负面提示词：(字数限制{NEGATIVE_PROMPT_WORD_LENGTH}字)
        </div>
        <TextArea
          className="promptWordText"
          value={negativePromptWordText}
          placeholder="输入不想让画面出现的元素，用逗号隔开，举例如下： 如：非法内容，裸露，抽烟，模糊，动物，畸形，坏手， 扭曲，多手指，少手指，和服，龅牙，恐怖..."
          rows={5}
          maxLength={NEGATIVE_PROMPT_WORD_LENGTH}
          showCount
          onChange={(e) => setNegativePromptWordText(e)}
        />
      </div>

      <div className="lineContent"></div>

      {/* 使用说明 */}
      <div className="instructionsContent">
        <div className="instructions">
          <div className="title">使用说明:</div>
          <div className="description">
            {INSTRUCTIONS.length > 0 &&
              INSTRUCTIONS.map((item, index) => (
                <div key={index} className="item">
                  {item}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="footer">
        <Button className="submitButton" onClick={handlerSubmit}>
          提交生成
        </Button>
      </div>
    </div>
  );
};
export default Generator;
