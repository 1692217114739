// import { useState, useEffect } from "react";
// import wx from "weixin-js-sdk";

import "./index.less";

// 会员福利
const Welfare = () => {

  return (
    <div className="welfareContent">
      Customiz...
    </div>
  );
};
export default Welfare;
