import { useState } from "react";
import { isArray } from "lodash-es";
import VideoModule from '@/components/VideoModule';
import "./index.less";

interface ImageLatticeProps {
  items: { image: string, video: string, name: string }[];
}

const ImageLattice = ({ items }: ImageLatticeProps) => {
  const [isPlay, setPlay] = useState<boolean>(false);
  const [img, setImg] = useState<string>('');
  const [video, setVideo] = useState<string>('');

  const handlePlay = (item: any) => {
    const { image, video } = item;
    setImg(image);
    setVideo(video);
    setPlay(true);
  };

  const closePlay = () => {
    setPlay(false);
  }

  return (
    <div className='imageLatticeContent'>
      <VideoModule isPlay={isPlay} img={img} video={video} closePlay={closePlay} />
      {isArray(items) && items.length > 0 && items.map((item, index) =>
        <div key={index} className='item' onClick={() => handlePlay(item)}>
          <img src={item.image} width={'100%'} />
          <span>{item.name}</span>
        </div>
      )}
    </div>
  );
};

export default ImageLattice;