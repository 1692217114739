
export const VIP_TYPE_OPTIONS = [
  {
    label: '月卡会员',
    value: 1,
    describe: '限时优惠',
    date: '1个月',
    price: '9.9',
    preferential: '59.9',
  },
  {
    label: '年卡会员',
    value: 2,
    describe: '限时优惠',
    date: '12个月',
    price: '89.9',
    preferential: '659',
  },
];

export const PAY_TYPE_OPTIONS = [
  {
    label: '微信支付',
    value: 'wechat',
  },
];

// 使用说明
export const DESCRIPTION_OPTIONS = [
  '天罡Al提供的LLM接口为文心一言ERNIE-4.0-8K;',
  '目前天罡AI具备的多模态能力为跨语种文字对话、图片识别及文生图，暂不具备语音交互、图生图、文（图）生视频等功能，如有版本迭代将通过公众号发出通告;',
  '定向知识库服务的用户在寻求专业解答时请须在问题文本中先输入定向关键字符（含冒号），如：“魔术丝：宣传片”、“魔术丝：成功案例”，未输入关键字符则进行正常对话交互;',
  '天罡AI由东楚天罡国际文化体育发展（北京）有限公司开发运营，公司提供人工智能全领域定制开发服务，咨询及客服电话400-001-8828;',
];

// 同意书
export const CONSENT_FORM_OPTIONS = [
  '《天罡AI微信公众号会员服务协议》（以下简称为“本协议”）是您与东楚天罡国际文化体育发展（北京）有限公司（以下统称“东楚天罡”）之间关于“天罡AI会员”以及“天罡AI会员”中东楚天罡提供的各项服务的法律协议，具有合同效力。',
  '东楚天罡在此特别提醒，请您在使用天罡AI会员服务前阅读并充分理解本协议，特别是免除或者限制责任的相应条款（该等免责、限制条款可能以黑体加粗、颜色标记或其他合理方式提示您注意），并选择“接受”或“不接受”。',
  '东楚天罡可能会根据天罡AI会员的整体规划等，对天罡AI会员及/或本协议进行修改或变更，若您不同意天罡AI会员及/或本协议的前述修改或变更，您可停止使用天罡AI会员服务。您使用天罡AI会员服务即视为您接受并同意本协议所有条款，包括但不限于前述修改及变更。如违反本协议约定，东楚天罡有权随时中止或终止该天罡AI会员服务。',
  '如果您未满18周岁，请在法定监护人的陪同下阅读本协议。',
  '特别提示：您开通天罡AI会员服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向东楚天罡提出任何主张或追究东楚天罡任何责任。',
  '一、定义和说明',
  '1.1【天罡AI会员及账号】',
  '天罡AI会员服务是东楚天罡在“天罡AI”微信公众号中设立的网络增值服务。您如果需要使用和享受天罡AI会员服务，则您需要将您享有合法使用权的微信账号作为会员账号，并购买相应的会员服务，您即成为天罡AI会员用户。',
  '1.2【本服务】',
  '在本协议中，“本服务”特指“天罡AI会员服务”，指用户通过付费购买或好友赠送等方式获取的增值权益服务，天罡AI会员具体权益种类和内容以 “会员权益介绍”页面及公众号文章和相关服务页面和公众号文章公布、实际提供的内容为准。您可以根据您的需求自行选择、使用相应的服务。',
  '东楚天罡郑重声明：您理解并同意，鉴于网络服务的特殊性，东楚天罡有权根据市场情况进行会员权益及特色会员服务的全部或部分变更、调整、取消、增加，并可能会对您已享有或正在享有的会员权益造成影响。您同意，如您继续按照调整后的内容使用天罡AI会员服务，您不要求东楚天罡承担任何责任。',
  '1.3【天罡AI会员规则】',
  '即天罡AI会员服务提供方不时发布并修订的关于天罡AI会员的用户守则、活动规则、使用规则、公告、提示、通知及在线FAQ等内容。',
  '1.4【协议更新】',
  '东楚天罡可以对本协议下服务条款进行更新（包含变更、增加、减少相应的条款内容），一旦服务条款发生更新，东楚天罡会通过公众号推送通知和提醒您注意。更新条款一经正式发布，即为本协议不可分割的组成部分。如果您不同意变更后的协议，可以停止对本服务的使用。您继续使用东楚天罡提供的服务，则视为您已经接受本协议的全部修改。',
  '1.5【本协议】',
  '本协议是基于《天罡AI用户协议》、《天罡AI个人信息保护规则》、《使用天罡AI前必读》、《天罡AI支付协议》以及天罡AI相关协议规范（简称为“天罡AI服务条款”）制定，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。',
  '同时，东楚天罡不时发布的关于天罡AI会员的相关协议、服务规则等也是本协议的一部分，您在使用天罡AI会员服务的同时应遵守本协议、天罡AI服务条款及东楚天罡旗下相关会员产品协议、服务规则等所有条款。',
  '二、账号管理及使用规则',
  '2.1 【账号管理】',
  '2.1.1 您需先付费购买天罡AI会员，方可使用天罡AI会员服务，默认登录账号为您享有合法使用权的微信账号。您应正确使用及妥善保管、维护您的天罡AI账号及密码，如发生任何泄漏、遗失、被盗等行为，而该等行为并非东楚天罡法定过错导致，所有损失将由您自行承担。',
  '2.1.2 您在此授予东楚天罡在您使用天罡AI过程中保护您账号安全的相关权利，东楚天罡可定期或不定期采用不同的方式对您天罡AI账号的使用安全进行检查、验证，包括但不限于主动联系您进行身份验证、短消息验证、邮箱认证及密码修改等。如您无法完成验证或无正当理由拒绝验证的，东楚天罡可合理怀疑您的账号出现异常或被盗，并中止向该账号提供服务或采取进一步措施。',
  '2.2 【使用规则】',
  '2.2.1【合法获取及使用】',
  '(1) 天罡AI会员服务，均仅限于您在“天罡AI”微信公众号内使用，任何以破解、转译、转录等非法手段将天罡AI会员服务与“天罡AI”微信公众号分离的行为，均不属于本协议中约定的天罡AI会员服务。东楚天罡保留追究侵权人的法律责任及索赔的权利。',
  '(2) 使用天罡AI会员服务及/或参加相关活动时，亦应通过东楚天罡官方公布/授权的方式进行，不得采取出售、转让、盗用、租赁其他用户账户等方式进行天罡AI会员注册或通过出售、转让、盗用、转借其他天罡AI会员账户等方式享用天罡AI会员。任何以盗窃、利用系统漏洞、通过任何非东楚天罡官方或授权途径获得的天罡AI会员服务（包括但不限于购买、租用、借用、分享、受让等方式获得）、恶意利用或破坏天罡AI会员活动获得的天罡AI会员服务（包括且不限于账号、会员权益等）或参加活动获取福利（包括但不限于获得实体/虚拟礼品、会员权益等），均不获相关保护，且一经发现东楚天罡有权立即封停账户及/或取消其天罡AI会员资格，且不给予任何赔偿或退还费用，所有产生的损失及责任由行为人自行承担。',
  '(3) 您成为天罡AI会员后，可享有天罡AI会员的各项专属特权和增值服务，同时应遵守天罡AI会员服务的相关协议及服务规则等。',
  '(4) 如您违反本协议或东楚天罡服务条款，东楚天罡有权利取消您的天罡AI会员资格，东楚天罡可根据您的实际情况采取修改、限制、中止或终止全部或部分天罡AI会员服务或追究您的法律责任等措施，东楚天罡也无需向您退还任何费用，且您需承担您、第三方及/或东楚天罡由此遭受的损失。',
  '2.2.2 【专有及非商业目的使用】',
  '(1) 天罡AI会员服务，是在您遵守本协议及相关法律法规的前提下，东楚天罡给予您一项个人的、不可转让及非排他性的许可。您仅可为非商业目的使用，并仅可用作私人使用。',
  '(2) 您理解并同意，您不得将享有天罡AI会员服务的东楚天罡账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用、分享、出售等方式提供给他人使用。否则，因此产生任何法律后果及责任均由您自行承担，一经发现东楚天罡有权中止或终止对您提供服务。',
  '2.2.3 【账号独立】',
  '(1) 同一主体拥有多个天罡AI会员账号的，每一个天罡AI账号将被识别为一个独立的账号。',
  '(2) 每个天罡AI账号之间的付费记录、会员权益无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等账号是否由同一使用人拥有，请您在登录及/或付费/参加活动时注意区分，避免造成损失。',
  '2.2.4 【妥善保管】',
  '请您负责妥善且正确地保管、使用、维护您的天罡AI会员资料、账户信息及账户密码。请您对您账户信息和账户密码采取必要和有效的保密措施。',
  '三、会员服务内容',
  '3.1 【会员特权】',
  '3.1.1 在您开通天罡AI会员服务后，根据您的会员购买情况将会享有对应服务体验，具体以相关服务页面和“天罡AI”微信公众号推文公布、实际提供的内容为准。',
  '3.1.2 您在购买天罡AI会员后可享受的全部权益以东楚天罡官方公布的会员权益内容为准，东楚天罡有权基于自身业务发展需要变更全部或部分会员权益。就前述权益调整东楚天罡会通过在相应服务页面更新展示、系统提示、信息推送方式通知和提醒您注意。同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益。',
  '3.1.3 为了向您提供更好的平台服务，东楚天罡可能会对会员权益进行调整，包括但不限于：',
  '（1）会员权益具体内容的调整',
  '（2）会员权益等级等计算方式的变更',
  '（3）会员付款方式、价格等的变更',
  '（4）其他平台公示的变更内容',
  '3.2 【权益独立】',
  '天罡AI会员服务提供的会员权益与东楚天罡旗下其它产品会员提供的权益独立不冲突，即您在开通天罡AI会员服务后，如您同时也开通东楚天罡旗下其它产品相应会员服务的，所涉及重合的权益内容您可叠加享受。',
  '3.3 【虚拟产品】',
  '基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金，亦不得用于商业领域，如买卖、置换、抵押等。为了保证您的用户权益，请您通过官方渠道购买天罡AI会员服务，一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，东楚天罡有权中止或终止对您的服务；由此带来的损失，东楚天罡不承担相关责任。',
  '3.4 【设备及系统差异】',
  '您通过“天罡AI”微信公众号开通本服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因等导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，您不予追究或者豁免东楚天罡的相关责任。',
  '3.5 【关于收费】',
  '本服务是东楚天罡提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。涉及收费环节时，我们将在相关用户界面上做出提示。如您拒绝支付费用，则无法使用天罡AI会员服务。',
  '此外，您可能也可以通过接受好友赠送等东楚天罡认可的其他方式享有、使用本服务。',
  '东楚天罡可能会根据本服务的整体规划，对本服务收费标准、方式等进行修改和变更，前述修改、变更，东楚天罡将在相应服务页面进行更新展示。东楚天罡会根据实际状况，对不同阶段付费会员给予不同的优惠，具体优惠政策和收费规则以东楚天罡在相关服务页面公示的信息为准。您在此理解并同意，如果东楚天罡发现用户滥用收费规则获取优惠等任何作弊的行为，可以判定该等用户享有的优惠无效。',
  '3.6 【支付】',
  '3.6.1 您应该通过东楚天罡指定的微信支付方式或今后东楚天罡指定方式，依约支付。',
  '请您在购买本服务和支付费用前仔细阅读并理解本协议和相关的收费规则、政策。如您不同意，请您停止支付行为。如果您实际进行了付费行为，则代表您同意本协议与相关收费规则，您无权转让本服务或要求东楚天罡退款；但如果因东楚天罡产品技术原因，导致您购买时被重复扣款或因东楚天罡产品技术原因导致您无法享有购买的服务时，您可提出退款申诉，经过东楚天罡核实后可为您办理退款，但您需要提供相应信息并配合东楚天罡处理。',
  '3.6.2 您不得通过以下任何方式为自己或他人开通本服务：',
  '(1) 以营利、经营等非个人使用的目的为自己或他人开通本服务；',
  '(2) 通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；',
  '(3) 通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；',
  '(4) 通过非东楚天罡指定的方式为自己或他人开通本服务；',
  '(5) 通过侵犯东楚天罡或他人合法权益的方式为自己或他人开通本服务；',
  '(6) 通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务；',
  '3.7 【服务期限】',
  '3.7.1 您理解并同意，本服务具有一定的服务期限。东楚天罡可能向您提供可选择的服务期限（如月度、季度、年度等，具体以产品页面为准）。除本协议另有约定外，本服务的期限以您自行选择并支付相应服务费用的期限为准，自您选择并购买本服务后生效。本服务的有效日期以天罡AI会员服务开通的时间算起，直至选定的服务期限到期或您终止本服务/注销东楚天罡账号或您因违规使用导致账号封禁时终止。您可以在本服务的相应页面查询服务期限选项与剩余服务期限。',
  '3.7.2 东楚天罡在此特别提示，您应当在会员权益载明的有效期内使用。逾期未使用导致权益失效的，即视为您自动放弃该权益。',
  '3.7.3 您的天罡AI会员到期前，您可再次通过购买/获赠等具体方式延续您的天罡AI会员。若您的天罡AI会员到期时，东楚天罡将终止提供天罡AI会员服务。',
  '3.7.4 请您理解，由于互联网服务的特殊性，为向您提供优质服务，东楚天罡须不定时进行服务器的维修、调整、升级等，且在黑客攻击、计算机病毒侵入、监管机构政策通知等不可抗力事件发生后，东楚天罡须解决故障并处理相关侵权事宜，因此您接受，本服务的服务期限包含东楚天罡解决故障、服务器维修、调整、升级、因第三方侵权处理等所需的合理时间，东楚天罡对上述情况所需用的时间不另行补偿。',
  '3.8 【关于广告的特别说明】',
  '您理解并同意，您在使用天罡AI会员的服务过程中，可能将接触到以各种方式投放的商业性广告或其他类型的商业信息；且您理解并同意，东楚天罡可以通过公众号向您发送促销信息、内容推荐或其他相关商业信息。',
  '我们依照法律的规定对广告商履行相关义务，但请您审慎判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您应自行承担因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害。',
  '3.9 【服务保障】',
  '东楚天罡在为天罡AI会员提供多项专属特权和增值服务同时，尽最大努力确保天罡AI会员服务的稳定性，为此提供各项技术及客服支持，包括但不限于：',
  '3.9.1 开通、获赠成为天罡AI会员期间所遇到的登陆、购买、兑换、赠送等问题的网上咨询客户服务；',
  '3.9.2 天罡AI会员服务积极有效的内容支持和技术支持，包括日常运营、维护、修复及更新；',
  '3.9.3 天罡AI会员服务提供期间产生的常见问题解答、基本规则解释；',
  '3.9.4 尽最大努力防止天罡AI会员服务的服务器和技术设备遭受恶意攻击，以保障您的合法利益；',
  '3.9.5 东楚天罡提供给您的其他技术和/或服务。',
  '3.10 【保密责任】',
  '尊重天罡AI用户个人隐私是东楚天罡的责任，东楚天罡在未经天罡AI会员的授权时不会公开或透露其天罡AI会员资料及保存的非公开内容，但由于法律法规需要等原因除外。',
  '四、违约责任',
  '4.1 您在使用本服务的过程中，不得进行以下违约行为：',
  '4.1.1 违反本协议约定的；',
  '4.1.2 违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；',
  '4.1.3 破坏、影响东楚天罡对任何第三方提供本服务；',
  '4.1.4 进行危害计算机网络安全的行为；',
  '4.1.5 对东楚天罡及关联第三方（包括授权方和合作方）提供的本协议服务、活动造成不良影响，侵犯及/或关联第三方东楚天罡及/或其他用户的正当利益；',
  '4.1.6 被有权机构认定侵犯任何第三方的合法权益的；',
  '4.1.7 利用本服务获取非法利益的，包括但不限于通过转售、转让、转授权等行为不正当牟利；',
  '4.1.8 其他侵犯东楚天罡合法利益的行为。',
  '4.2 【违约行为处理】',
  '您确认并同意，如果东楚天罡发现或收到他人举报您有违反本协议任何行为的，东楚天罡有权依法进行独立判断并采取以下一项或多项措施处理：',
  '(1) 根据相应规则立即删除、屏蔽或断开相关的信息；',
  '(2) 采取包括但不限于中止或终止部分或全部本服务的措施；',
  '(3) 采取扣除违约行为发生时间内的会员权益时长的措施；',
  '(4) 东楚天罡无需向您退还任何费用，未使用的服务费用作为违约金归东楚天罡所有，您的相关会员权益的损失由您自行承担，东楚天罡在法律有明确规定的情况下承担相应的责任；',
  '(5) 如您的行为使东楚天罡遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿东楚天罡的上述全部损失；',
  '(6) 如您的行为使东楚天罡遭受第三人主张权利，东楚天罡可在对第三人承担金钱给付等义务后就全部损失向您追偿。',
  '五、服务的变更、中止及终止',
  '5.1 本服务的中止或终止包含如下情况：',
  '5.1.1用户主动中止或终止，包括但不限于会员到期未进行续费等；',
  '5.1.2 因为用户违约行为，东楚天罡主动中止或终止服务的；',
  '5.1.3 因国家或相关部门要求或发生不可抗力事件时，东楚天罡中止或终止服务的；',
  '5.1.4 其他根据法律法规或东楚天罡业务调整等应当或者需要中止或终止服务的；',
  '5.2 中止或终止服务后，东楚天罡将尽最大努力通过合理方式通知用户。当您发现无法登录或享受服务时，可以咨询客服热线。',
  '5.3 中止或终止后的处理：',
  '5.3.1 除法律规定的责任外，东楚天罡对本服务的中止或终止对用户和任何第三人不承担任何责任；',
  '5.3.2 除法律规定或本协议另有约定外，已收取的费用不予退还；',
  '5.3.3 正在进行的交易，东楚天罡将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。',
  '5.4 东楚天罡依据本协议的约定变更服务内容、服务方式以及本协议内容的，如果会员不同意东楚天罡的变更，有权选择取消并停止使用已经获取的对应的全部或部分服务；如果会员继续使用东楚天罡提供的上述服务，则视为会员已经接受东楚天罡的上述调整。',
  '5.5 会员如果不同意条款的修改，可主动向东楚天罡提出终止会员服务，但东楚天罡不退还自开通会员服务至提出终止会员服务所在月的已享受会员服务的部分费用；如果会员继续享用会员服务，则视为会员已经接受条款的修改。',
  '六、通知和送达',
  '本协议项下东楚天罡对于用户所有的通知均可通过公众号推文、微信聊天窗口对话、网页链接、手机短信等方式进行，该等通知于发送之日视为已送达用户。东楚天罡可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注。',
  '七、法律的适用和管辖',
  '7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。',
  '7.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向东楚天罡所在地有管辖权的人民法院提起诉讼。',
  '7.3 东楚天罡未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。',
  '7.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。',
  '7.5 在法律法规允许的范围内，东楚天罡有权对本协议进行解释。',
  '东楚天罡国际文化体育发展（北京）有限公司',
  '本版更新时间：2024年4月20日',
];