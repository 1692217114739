import "./index.less";

// 使用说明
const Instructions = () => {

  return (
    <div className="insContent">
      <h1>使用说明：</h1>
      <div className="center">
        <ul>
          <li>本页面功能均为收费项目（价格见详情页），会员与非会员价格不同；</li>
          <li>用户禁止上传含有违法内容的原图，否则一经发现直</li>
          <li>如不会操作请参考使用指南，聊天页面点击：订阅管理-使用指南；</li>
          <li>如有意见建议、问题反馈请联系400-001-8828。</li>
        </ul>
      </div>
    </div>
  );
};
export default Instructions;
