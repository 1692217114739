import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import 个性定制 from '@/assets/images/二级/个性定制.png';
import "./index.less";

// 个性定制
const Appointment = () => {

  const makingPhoneCalls = () => {
    window.location.href = "tel:4000018828";
  }

  return (
    <div className="appointmentContent">
      <TipsBox title='私人订制' desc='请按照以下方式联系客服沟通定制' />
      <TipsBox title='拨打 400-001-8828' desc='' />
      <a className="appointmentBtn" onClick={makingPhoneCalls}>立即预约</a>
      <TipsBox title='or' desc='扫描下方二维码添加微信' />
      <ImageModule imgs={个性定制} />
    </div>
  );
};
export default Appointment;
