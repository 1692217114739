export const PHOTOS_TYPE = [
  { name: '男士', num: 20 },
  { name: '毕业季', num: 12 },
  { name: '美女', num: 20 },
  { name: '个性', num: 14 },
  { name: '日常', num: 14 },
  { name: '户外', num: 18 },
  { name: '复古', num: 14 },
  { name: '文体', num: 18 },
  { name: '服饰', num: 16 }
];