import { useState, useRef } from "react";
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import DownloadImage from '@/components/DownloadImage';
import Upload from './components/Upload';
import Draw from './components/Draw';
import PayComponents from '@/components/Pay';
import 移除工具_头图 from '@/assets/images/二级/移除工具_头图.png';
import "./index.less";

interface DaubToolHandle {
  downloadDaubedImage: (e: any) => string;
}

// 图片工具
const Drawing = () => {
  // 禁止滚动
  const [overflow, setOverflow] = useState<string>('auto');
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const daubToolRef = useRef<DaubToolHandle | null>(null);
  const [generateGraph, setGenerateGraph] = useState<string>(null);

  // 生成图片
  const handleGenerate = () => {
    daubToolRef.current.downloadDaubedImage((dataURL) => {
      console.log('dataURL', dataURL);
      setGenerateGraph(dataURL);
    });
  
  };

  return (
    <div className="drawingContent" style={{ overflow }}>
      <TipsBox title='移除工具' desc='移除画面中不想要的元素' />
      <ImageModule imgs={移除工具_头图} />
      <Upload setSelectedImage={setSelectedImage} />
      <>
        {selectedImage &&
          <div>
            <Draw selectedImage={selectedImage} ref={daubToolRef} setOverflow={(e) => setOverflow(e)} />
            {generateGraph && <DownloadImage imgs={generateGraph} />}
            <PayComponents price='支付￥1元(会员￥0.5元)' desc='付费提交生成后，请等待15~20秒，在上面橙色画框中等待最终成品图片，可直接下载保存' callbackPay={handleGenerate} />
          </div>
        }
      </>
    </div>
  );
};
export default Drawing;
