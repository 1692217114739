import axios from "axios";
// import { Modal } from 'antd-mobile'
//import { toast } from "react-hot-toast";
//import { backToLogin } from "./store";

const bussinessRequest = async <T>(config: {
  url: string;
  method?: "GET" | "POST" | "DELETE" | "PUT";
  data?: Record<string, unknown>;
}): Promise<[Error | null, T | null]> => {
  const { url, method = "GET" } = config;

  try {
    const result = await axios<{
      success: boolean;
      data: any;
      errorCode: number;
      errorMessage: string;
      message: string;
      code: number;
    }>(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      params: method === "GET" ? config.data : undefined,
      data: config.data,
    });
    const { data } = result || {};
    const { message = '', code } = data || {};
    if (data && code === 200) {
      return [null, {
        ...data.data,
        message,
      }];
    } else {
      // Modal.alert({
      //   content: `${message}`,
      //   onConfirm: () => {},
      //   closeOnMaskClick: true,
      // })
      return [new Error(message), null];
    }
  } catch (e) {
    console.error(e);
    return [new Error("请求失败"), null];
  }
};

export default bussinessRequest;
