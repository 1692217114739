import { FC } from 'react';
import { Image } from 'antd-mobile';
import useTypewriter from 'react-typewriter-hook';
import imageLoad from '@/assets/images/imageLoad.png';
import './index.less';

interface TextPicturesProps {
  text: string;
  image: string;
  height: number;
}

const TextPictures: FC<TextPicturesProps> = ({ text, image, height }) => {
  const talk = useTypewriter(text); 
  return (
    <div className='textPicturesContent'>
      <Image className='img' src={image} lazy fit='contain' fallback={imageLoad} />
      <div className='text' style={{ height }}>{talk}</div>
    </div>
  );
};

export default TextPictures;