// import { useState, useEffect } from "react";
// import { Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import TipsBox from '@/components/TipsBox';
import DragSlider from '@/components/DragSlider';
import Instructions from '@/components/Instructions';
import TextPictures from '@/components/TextPictures';
import 文生图 from '@/assets/images/preview01.png';
import 图片识别 from '@/assets/images/一级/个性定制_科幻视频.png';
import 移除工具_前 from '@/assets/images/一级/图片工具_移除工具_前.png';
import 移除工具_后 from '@/assets/images/一级/图片工具_移除工具_后.png';
import 扩图工具_前 from '@/assets/images/一级/图片工具_扩图工具_前.png';
import 扩图工具_后 from '@/assets/images/一级/图片工具_扩图工具_后.png';
import 去除背景_前 from '@/assets/images/一级/图片工具_去除背景_前.png';
import 去除背景_后 from '@/assets/images/一级/图片工具_去除背景_后.png';
import 图像增强_前 from '@/assets/images/一级/图片工具_图像增强_前.png';
import 图像增强_后 from '@/assets/images/一级/图片工具_图像增强_后.png';
import "./index.less";

// 图片工具
const Drawing = () => {
  const navigate = useNavigate();

  const toLink = (path) => {
    navigate(path);
  }

  return (
    <div className="drawingContent">
      <TipsBox title='文生图' desc='输入画面描述文字生成图片' />
      <TextPictures text='正面提示词： 一只金毛狗坐在咖啡馆门口的 露天餐桌旁，戴着眼镜看报纸， 耳朵戴着耳机听音乐，餐桌上放 着一杯热咖啡冒着热气。' image={文生图} height={100} />
      <a className='entryBtn' onClick={() => toLink('textToImage')}>文字生图</a>

      <TipsBox title='图片识别' desc='智能识别上传图片的文字/图像内容' />
      <TextPictures text='这张图片展示了一辆金色的大型卡车，它似乎正在公路上行驶。这辆卡车的设计非常豪华和独特，带有“ROLLS ROYCE”的标志，暗示了其品牌身份。车辆的前部有明显的散热格栅和车头灯，侧面则装饰着一些细节元素。整个场景被渲染得相当逼真，包括卡车在阳光下的反光效果以及周围环境的模糊动态感，给人一种运动中的感觉。背景中还有树木和其他道路元素，增加了图像的真实感。' image={图片识别} height={280} />
      <a className='entryBtn' onClick={() => toLink('imageToText')}>图文转文字</a>

      <TipsBox title='移除工具' desc='移除画面中不想要的元素' />
      <DragSlider beforeImage={移除工具_前} afterImage={移除工具_后} />
      <a className='entryBtn' onClick={() => toLink('drawing')}>开始移除</a>

      <TipsBox title='扩图工具' desc='向上下左右扩展填充图片' />
      <DragSlider beforeImage={扩图工具_前} afterImage={扩图工具_后} />
      <a className='entryBtn' onClick={() => toLink('expansion')}>扩展图片</a>

      <TipsBox title='去除背景' desc='一键去除主体之外的所有元素' />
      <DragSlider beforeImage={去除背景_前} afterImage={去除背景_后} />
      <a className='entryBtn' onClick={() => toLink('background')}>去除背景</a>

      <TipsBox title='图像增强' desc='将原始图片高清放大4倍' />
      <DragSlider beforeImage={图像增强_前} afterImage={图像增强_后} />
      <a className='entryBtn' onClick={() => toLink('enhancement')}>图像增强</a>

      <Instructions />
    </div>
  );
};
export default Drawing;
