import create from 'zustand'
import { immer } from 'zustand/middleware/immer';
import { DRAWING_KEY } from '@/constants';

interface StoreState {
  userInfo: any;
  tabsValue: string;
}
interface StoreActions {
  changeSetUserInfo: (param: any) => void;
  changeUseTabsTabsValue: (param: string) => void;
}

const INIT_STATE: StoreState = {
  userInfo: {},
  tabsValue: DRAWING_KEY,
};


const useStore = create(
  immer<StoreState & StoreActions>((set) => ({
    ...INIT_STATE,
    changeSetUserInfo: async (param: any) => {
      set(() => ({ userInfo: param }))
    },
    changeUseTabsTabsValue: (param: string) => {
      set(() => ({
        tabsValue: param,
      }))
    }
  })),
);

export default useStore;