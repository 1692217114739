import { useState, useRef, ChangeEvent } from "react";
import { isString } from 'lodash-es';
import { AddCircleOutline } from 'antd-mobile-icons'
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import PayComponents from '@/components/Pay';
import SelectPhotos from "./components/SelectPhotos";
import 图片换脸 from '@/assets/images/一级/热门娱乐_图片换脸.png';
import 图片换脸_前 from '@/assets/images/二级/热门娱乐_图片换脸_前.png';
import 图片换脸_后 from '@/assets/images/二级/热门娱乐_图片换脸_后.png';
import "./index.less";

// 图片换脸
const PictureFace = () => {
  const [selectedImageA, setSelectedImageA] = useState<string>(null);
  const [selectedImageB, setSelectedImageB] = useState<string>(null);
  const hiddenFileInputA = useRef<HTMLInputElement>(null);
  const hiddenFileInputB = useRef<HTMLInputElement>(null);
  const [isSelectPhotos, setIsSelectPhotos] = useState<boolean>(false);

  const handleImageUploadA = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImageA(URL.createObjectURL(file));
      event.target.value = null;
      setIsSelectPhotos(false);
    } else {
      alert('请选择照片!');
    }
  };
  const handleImageUploadB = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImageB(URL.createObjectURL(file));
      event.target.value = null;
    } else {
      alert('请选择照片!');
    }
  };

  const handleSelectUptade = () => {
    hiddenFileInputA.current?.click();
  }

  const handleSetSelectedImageA = (img) => {
    setSelectedImageA(img);
    setIsSelectPhotos(false);
  }

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }

  return (
    <div className="pictureFaceContent">
      {isSelectPhotos ?
        <SelectPhotos handleClick={handleSelectUptade} handleSetSelectedImageA={handleSetSelectedImageA} />
        :
        <>
          <TipsBox title='图片换脸' desc='上传照片一键换脸' />
          <ImageModule imgs={图片换脸} />
          <div className="contrast">
            <div className="imgList">
              <div className="itemContent" onClick={() => setIsSelectPhotos(true)}>
                <img className="itemImage" src={selectedImageA ? selectedImageA : 图片换脸_前} />
                <AddCircleOutline className="itemBefore" />
              </div>
              <div className="itemContent" onClick={() => hiddenFileInputB.current?.click()} >
                <img className="itemImage" src={selectedImageB ? selectedImageB : 图片换脸_后} />
                <AddCircleOutline className="itemBefore" />
              </div>
            </div>
            <div className="descList">
              <div className="text">点击上方+号，上传模板图片或手机相册中要被换脸的原始图片</div>
              <div className="text">点击上方+号，上传手机相册中您想要生成最终肖像的图片</div>
            </div>
          </div>

          <div className="pictureFaceinsContent">
            <h1>使用说明：</h1>
            <div className="center">
              <ul>
                <li>上传图片仅支持JPG/PNG/WEBP等格式;</li>
                <li>上传图片要求近景或特写，头部完整、面部清洗、五官无遮挡或较少遮挡、未闭眼;</li>
                <li>上传图片大小不能超过5M</li>
              </ul>
            </div>
          </div>
          {selectedImageA && isString(selectedImageA) && selectedImageA !== '' && selectedImageB && isString(selectedImageB) && selectedImageB !== '' &&
            <div className='footer'>
              <PayComponents price='支付￥2元(会员￥1元)' desc='付费提交生成后，请等待10~15秒，在最上面橙色画框中等待最终3张成品图片，选择效果最好的下载保存' callbackPay={callbackPay} />
            </div>
          }
        </>
      }

      <input
        type="file"
        ref={hiddenFileInputA}
        onChange={handleImageUploadA}
        style={{ display: 'none' }}
        accept="image/*"
      />
      <input
        type="file"
        ref={hiddenFileInputB}
        onChange={handleImageUploadB}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </div>
  );
};
export default PictureFace;
