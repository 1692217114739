import { useState, useRef, ChangeEvent } from "react";
import { isString } from 'lodash-es';
import { AddCircleOutline } from 'antd-mobile-icons'
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import PayComponents from '@/components/Pay';
import SelectVideo from "./components/SelectVideo";
import 视频换脸 from '@/assets/images/一级/热门娱乐_视频换脸.png';
import 视频换脸_前 from '@/assets/images/二级/热门娱乐_视频换脸_前.png';
import 视频换脸_后 from '@/assets/images/二级/热门娱乐_视频换脸_后.png';
import "./index.less";

// 视频换脸
const VideoFace = () => {
  // 进度条
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedVideoA, setSelectedVideoA] = useState<string>(null);
  const [selectedVideoB, setSelectedVideoB] = useState<string>(null);
  const hiddenFileInputA = useRef<HTMLInputElement>(null);
  const hiddenFileInputB = useRef<HTMLInputElement>(null);
  const [isSelectPhotos, setIsSelectPhotos] = useState<boolean>(false);

  const handleImageUploadA = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files[0];
    if (file && file.type.startsWith('video/')) {
      uploadFile(file);
      event.target.value = null;
    } else {
      alert('请选择视频!');
    }
  };
  const handleImageUploadB = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files[0];
    if (file && file.type.startsWith('video/')) {
      uploadFile(file);
      event.target.value = null;
    } else {
      alert('请选择视频!');
    }
  };

  const uploadFile = (file: File) => {
    const xhr = new XMLHttpRequest();

    // Update progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
      }
    };

    // File uploaded
    xhr.onload = () => {
      if (xhr.status === 200) {
        // File uploaded successfully
        setSelectedVideoB('');
      }
    };

    xhr.open('POST', '/upload-endpoint', true);
    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
  };


  const handleSelectUptade = () => {
    hiddenFileInputA.current?.click();
  }

  const handleSetSelectedVideoA = (video) => {
    setSelectedVideoA(video);
    setIsSelectPhotos(false);
  }

  // 支付完成
  const callbackPay = () => {
    console.log('开始生成图');
  }
  console.log('进度条', uploadProgress);

  return (
    <div className="videoFaceContent">
      {isSelectPhotos ?
        <SelectVideo handleClick={handleSelectUptade} handleSetSelectedVideoA={handleSetSelectedVideoA} />
        :
        <>
          <TipsBox title='视频换脸' desc='上传视频一键生成换脸视频' />
          <ImageModule imgs={视频换脸} />
          <div className="contrast">
            <div className="imgList">
              <div className="itemContent" onClick={() => setIsSelectPhotos(true)}>
                <img className="itemImage" src={selectedVideoA ? `${selectedVideoA}.png` : 视频换脸_前} />
                <AddCircleOutline className="itemBefore" />
              </div>
              <div className="itemContent" onClick={() => hiddenFileInputB.current?.click()} >
                <img className="itemImage" src={selectedVideoB ? selectedVideoB : 视频换脸_后} />
                <AddCircleOutline className="itemBefore" />
              </div>
            </div>
            <div className="descList">
              <div className="text">点击上方+号，上传模板视频或手机相册中要被换脸的原始视频</div>
              <div className="text">点击上方+号，上传手机相册中您想要生成最终容貌的视频</div>
            </div>
          </div>

          <div className="pictureFaceinsContent">
            <h1>使用说明：</h1>
            <div className="center">
              <ul>
                <li>上传视频仅支持MP4/MOV/AVI等格式;</li>
                <li>上传视频要求近景或特写，头部完整、面部清洗、五官无遮挡或较少遮挡、动作幅度不可过大，动作速度不宜过快;</li>
                <li>上传视频大小不能超过100M，时长不超过60秒</li>
              </ul>
            </div>
          </div>
          {selectedVideoA && isString(selectedVideoA) && selectedVideoA !== '' && selectedVideoB && isString(selectedVideoB) && selectedVideoB !== '' &&
            <div className='footer'>
              <PayComponents price='支付￥5元(会员￥2元)' desc='付费提交生成后，请等待60~90秒，在最上面橙色画框中等待最终3张成品视频，选择效果最好的下载保存' callbackPay={callbackPay} />
            </div>
          }
        </>
      }

      <input
        type="file"
        ref={hiddenFileInputA}
        onChange={handleImageUploadA}
        style={{ display: 'none' }}
        accept="image/*"
      />
      <input
        type="file"
        ref={hiddenFileInputB}
        onChange={handleImageUploadB}
        style={{ display: 'none' }}
        accept="video/*"
      />
    </div>
  );
};
export default VideoFace;
