// import { useState, useEffect } from "react";
// import wx from "weixin-js-sdk";
import { useNavigate } from 'react-router-dom';
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import Instructions from '@/components/Instructions';
import 图片换脸 from '@/assets/images/一级/热门娱乐_图片换脸.png';
import 视频换脸 from '@/assets/images/一级/热门娱乐_视频换脸.png';
import AI写真 from '@/assets/images/一级/热门娱乐_AI写真.png';
import 个性头像 from '@/assets/images/一级/热门娱乐_个性头像.png';
import "./index.less";

// 热门娱乐
const Hot = () => {
  const navigate = useNavigate();

  const toLink = (path) => {
    navigate(path);
  }

  return (
    <div className="hotContent">
      <TipsBox title='图片换脸' desc='上传图片一键换脸' />
      <ImageModule imgs={图片换脸} />
      <a className='entryBtn' onClick={() => toLink('pictureFace')}>开始 图片换脸</a>

      <TipsBox title='视频换脸' desc='上传视频一键生成换脸视频' />
      <ImageModule imgs={视频换脸} />
      <a className='entryBtn' onClick={() => toLink('videoFace')}>开始 视频换脸</a>

      <TipsBox title='AI写真' desc='上传照片生成4张自选风格写真' />
      <ImageModule imgs={AI写真} />
      <a className='entryBtn' onClick={() => toLink('aiFace')}>开始 AI写真</a>

      <TipsBox title='个性头像' desc='上传照片生成3张个性风格头像' />
      <ImageModule imgs={个性头像} />
      <a className='entryBtn' onClick={() => toLink('avatarFace')}>开始 个性头像</a>

      <Instructions />
    </div>
  );
};
export default Hot;
