import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PayPage from '@/page/Pay';
import Generator from '@/page/Generators';
import Draw from '@/page/Draw';
import Work from '@/page/Work';
import Welfare from '@/page/Welfare';
import App from './App';
// 文生图
import TextToImage from '@/page/TextToImage';
// 图片识别
import ImageToText from '@/page/ImageToText';
// 移除工具
import Drawing from '@/page/Drawing';
// 扩展图片
import Expansion from '@/page/Expansion';
// 去除背景
import Background from '@/page/Background';
// 图像增强
import Enhancement from '@/page/Enhancement';
// 图片换脸
import PictureFace from '@/page/PictureFace';
// 视频换脸
import VideoFace from '@/page/VideoFace';
// ai写真
import AIFace from '@/page/AIFace';
// 个性头像
import AvatarFace from '@/page/AvatarFace';
// 个性定制
import Appointment from '@/page/Appointment';
import './index.less';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <PayPage /> },
      { path: '/generate', element: <Generator /> },
      { path: "/work", element: <Work /> },
      { path: '/work/textToImage', element: <TextToImage /> },
      { path: '/work/imageToText', element: <ImageToText /> },
      { path: '/work/drawing', element: <Drawing /> },
      { path: '/work/expansion', element: <Expansion /> },
      { path: '/work/background', element: <Background /> },
      { path: '/work/enhancement', element: <Enhancement /> },
      { path: '/work/pictureFace', element: <PictureFace /> },
      { path: '/work/videoFace', element: <VideoFace /> },
      { path: '/work/aiFace', element: <AIFace /> },
      { path: '/work/avatarFace', element: <AvatarFace /> },
      { path: '/work/appointment', element: <Appointment /> },
      { path: "/welfare", element: <Welfare /> },
      { path: "/draw", element: <Draw /> },
      { path: "/about", element: <div>404</div> },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);
