import { Image } from 'antd-mobile'
import { isArray } from "lodash-es";
import arrowsPointingTowards from '@/assets/images/arrowsPointingTowards.png';
import "./index.less";

// 箭头图片，指向性
const ArrowPicture = ({ imgs }: any) => {
  return (
    <div className='arrowPictureContent'>
      <div className="imgsList">
        <img src={arrowsPointingTowards} className="arrowImg" />
        {isArray(imgs) && imgs.length > 0 && imgs.map((item, index) =>
          <div className="item" key={index}>
            <Image className='img' src={item} width={'100%'} lazy fit='contain' />
          </div>
        )}
      </div>
    </div >
  );
};
export default ArrowPicture;
