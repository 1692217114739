import { useState, } from "react";
import { useAsyncEffect } from "ahooks";
import wx from "weixin-js-sdk";
import toast, { Toaster } from 'react-hot-toast';
import useStore from '@/store';
import bussinessRequest from "../../server/request";
// import { isString } from "lodash-es";
// import { useAsyncEffect } from "ahooks";
// import { DOMAIN_NAME } from '../../constants';
import {
  VIP_TYPE_OPTIONS,
  PAY_TYPE_OPTIONS,
  DESCRIPTION_OPTIONS,
  CONSENT_FORM_OPTIONS,
} from "./constants";
// import { getCodeRedirect, saveToLocalStorage, getFromLocalStorage } from '../../utils';
import portrait from "@/assets/images/portrait.png";
import wechatpayImg from "@/assets/images/wechatpay.png";
import confirmImg from "@/assets/images/confirm.png";
import closeImg from "@/assets/images/closeImg.png";
import "./index.less";

// interface ResponseOpenId {
//   openId: string;
//   nickname: string;
//   headImgUrl?: string;
// }

interface ResponseData {
  success: boolean;
  memberStatus?: number | undefined;
  deadLine?: string;
  desc?: string;
}

interface ResponsePay {
  appId: string;
  timeStamp?: string;
  nonceStr?: string;
  packageVal?: any;
  signType?: string;
  paySign?: string;
}

interface ResponseConfig {
  appId: string;
  nonceStr?: string;
  timestamp?: string;
  url?: any;
  signature?: string;
}

const PayPage = () => {
  const userInfo = useStore((state) => state.userInfo);
  const changeSetUserInfo = useStore((state) => state.changeSetUserInfo);
  const { openId, nickname, headImgUrl, deadLine, desc, memberStatus } = userInfo || {};

  // const [openId, setOpenId] = useState<string | null>("");
  // const [nickname, setNickname] = useState<string | null>("");
  const [chargeType, setChargeType] = useState<number>(1);
  const [payType, setPayType] = useState<string>("wechat");
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isConsentForm, setIsConsentForm] = useState<boolean>(false);
  // const [headImgUrl, setHeadImgUrl] = useState<string | null>(null);
  // const [resData, setResData] = useState<string>('');
  // const navigate = useNavigate();

  // 获取openid
  // const getOpenId = async (code) => {
  //   const [err, data] = await bussinessRequest<ResponseOpenId>({
  //     url: "/api/wechat/login",
  //     method: "POST",
  //     data: { code },
  //   });
  //   if (!err && data) {
  //     const { openId = "", nickname = "", headImgUrl = "" } = data || {};
  //     if (openId && nickname) {
  //       saveToLocalStorage('authentication', openId)
  //       window.localStorage.setItem("nickname", nickname);
  //       window.localStorage.setItem("headImgUrl", headImgUrl);
  //       window.location.href = DOMAIN_NAME;
  //     }
  //   }
  // };

  // 获取config配置
  const getConfig = async () => {
    const [err, data] = await bussinessRequest<ResponseConfig>({
      url: `/api/payment/config`,
      method: "POST",
      data: {
        url: window.location.href,
      }
    });

    const { appId = '', nonceStr = '', timestamp = '', signature = '' } = data || {};
    if (!err && data) {
      wx.config({
        debug: false, // 开启调试模式
        appId, // 必填，公众号的唯一标识
        timestamp: parseInt(timestamp, 10), // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature,// 必填，签名
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
      });
    }
  };

  // 获取会员信息
  const getUserInfo = async (openId) => {
    const [err, data] = await bussinessRequest<ResponseData>({
      url: `/api/member?openId=${openId}`,
      method: "GET",
    });
    const { memberStatus = 0, deadLine = "", desc = "", } = data || {};
    if (!err && data) {
      getConfig();
      changeSetUserInfo({
        ...userInfo,
        memberStatus,
        deadLine,
        desc,
      })
    }
  };

  // 支付
  const handlerSubmit = async () => {
    console.log(isAgree, openId, chargeType);
    if (isAgree) {
      const [err, data] = await bussinessRequest<ResponsePay>({
        url: "/api/payment/prepay",
        method: "POST",
        data: {
          openId,
          chargeType,
        },
      });
      if (!err && data) {
        const {
          appId = '',
          timeStamp = '',
          nonceStr = '',
          signType = '',
          paySign = '',
          packageVal = '',
        } = data || {};
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          appId,
          timeStamp,
          nonceStr,
          package: packageVal,
          signType,
          paySign,
        }, (res) => {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            toast('支付成功');
            setIsSuccess(true);
            // setResData(JSON.stringify(res));
          } else {
            toast('支付失败');
          }
        })
      }
    } else {
      setIsConsentForm(true);
    }
  };

  // // 初始化
  useAsyncEffect(async () => {
    if (openId && openId !== '') {
      getUserInfo(openId);
    }
  }, [openId]);

  return (
    <div className="payPageContent">
      <div className="payTitle">订阅管理</div>

      {/** user 用户信息 */}
      <div className="banner">
        <img className={`${headImgUrl ? 'headImgUrl' : 'headPortrait'}`} src={headImgUrl ? headImgUrl : portrait} />
        <div className="detail">
          <div className="nickname">{nickname}</div>
          <div className="vip">
            {memberStatus === 0 && (
              <span style={{ color: "#626562" }}>{desc}</span>
            )}
            {memberStatus === 1 && (
              <span style={{ color: "#E96C32" }}>
                您已开通会员，有效期截止{deadLine}
              </span>
            )}
            {memberStatus === 2 && (
              <span style={{ color: "#626562" }}>当前会员已过期</span>
            )}
          </div>
        </div>
      </div>

      <div className="core">
        {/** vip 月卡/年卡 */}
        <div className="vipContent">
          {VIP_TYPE_OPTIONS.length > 0 &&
            VIP_TYPE_OPTIONS.map((item, index) => (
              <div
                className="priceItme"
                key={index}
                onClick={() => setChargeType(item.value)}
              >
                <div
                  className={`${chargeType === item.value ? "label labelLine" : "label"
                    }`}
                >
                  {item.label}
                </div>
                <div
                  className="content"
                  style={{
                    borderColor:
                      chargeType === item.value ? "#CFA972" : "#C6CFD3",
                  }}
                >
                  <div className="describe">{item.describe}</div>
                  <div className="date">{item.date}</div>
                  <div className="price">{item.price}</div>
                  <div className="preferential">{item.preferential}</div>
                </div>
              </div>
            ))}
        </div>

        {/** pay 支付方式 */}
        <div className="payContent">
          <div className="title">支付方式:</div>
          {PAY_TYPE_OPTIONS.length > 0 &&
            PAY_TYPE_OPTIONS.map((item, index) => (
              <div
                className="payItem"
                key={index}
                onClick={() => setPayType(item.value)}
              >
                <img src={wechatpayImg} className="ico" />
                <span>{item.label}</span>
                {payType === item.value && (
                  <img src={confirmImg} className="choose" />
                )}
              </div>
            ))}
        </div>

        {/** 使用说明 */}
        <div className="directionsForUse">
          <div className="title">使用说明:</div>
          {DESCRIPTION_OPTIONS.length > 0 &&
            DESCRIPTION_OPTIONS.map((item, index) => (
              <div className="directionsItem" key={index}>
                {item}
              </div>
            ))}
        </div>
      </div>
      <div className="lineContent"></div>

      {/** 同意并支付 */}
      <div className="informContent">
        <div className="confirm">
          <div className={isAgree ? "agree" : "disagree"} onClick={() => setIsAgree(!isAgree)}></div>
          <div className="text" onClick={() => setIsConsentForm(true)}>支付即同意《天罡AI微信公众号会员服务协议》</div>
        </div>

        <div className="submitButton" onClick={handlerSubmit}>
          {chargeType === 1 ? ' 确认协议并支付9.9元' : ' 确认协议并支付89.9元'}
        </div>
      </div>

      {/** 支付完成蒙层 */}
      {isSuccess && (
        <div className="successMantle">
          <div className="make" onClick={() => {
            setIsSuccess(false)
            getUserInfo(openId);
          }}></div>
          <div className="describe">已完成支付，感谢！</div>
        </div>
      )}

      {/** 协议蒙层 */}
      {isConsentForm && (
        <div className="consentForm">
          <div className="make" onClick={() => setIsConsentForm(false)}></div>
          <div className="describe">
            <img className="closeBtn" src={closeImg} onClick={() => setIsConsentForm(false)} />
            <div className="title">欢迎您成为天罡AI会员并阅读《天罡AI微信公众号会员服务协议》</div>
            <div className="content">
              {CONSENT_FORM_OPTIONS.length > 0 && CONSENT_FORM_OPTIONS.map((item, index) =>
                <div className="consentItem" key={index}>{item}</div>
              )}
            </div>
            <button onClick={() => {
              setIsConsentForm(false);
              setIsAgree(true);
            }}>确认同意</button>
          </div>
        </div>
      )}

      <Toaster />
    </div>
  );
};
export default PayPage;
