import React, { useEffect, useRef, useState } from 'react';
import beijing from './123.jpg';
import './index.less';

const DaubTool = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDaubing, setIsDaubing] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(50);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;

    ctx.current = context;

    // 设置画布样式
    context.lineWidth = canvas.width * 0.05;
    context.lineCap = 'round';
    context.strokeStyle = '#FFF';

    // 绘制背景图片
    const background = new Image();
    background.crossOrigin = 'anonymous';
    background.src = beijing;
    background.onload = () => {
      canvas.width = background.width;
      canvas.height = background.height;
      // 重新设置画笔的粗细
      context.lineWidth = canvas.width * 0.05;
      context.strokeStyle = '#FFF';
      context.drawImage(background, 0, 0, canvas.width, canvas.height);
    };

    background.onerror = (error) => {
      console.error('Failed to load image:', error);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.style.transform = `scale(${zoomLevel / 50})`;
    }
  }, [zoomLevel]);

  const startPosition = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.TouchEvent<HTMLCanvasElement>) => {
    setIsDaubing(true);
    const rect = canvasRef.current?.getBoundingClientRect();
    const x = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const y = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const scaleX = (canvasRef.current?.width || 0) / (rect?.width || 1);
    const scaleY = (canvasRef.current?.height || 0) / (rect?.height || 1);
    if (ctx.current) {
      ctx.current.beginPath();
      ctx.current.moveTo((x - (rect?.left || 0)) * scaleX, (y - (rect?.top || 0)) * scaleY);
    }
  };

  const finishedPosition = () => {
    setIsDaubing(false);
    if (ctx.current) {
      ctx.current.beginPath();
    }
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDaubing || !ctx.current) return;
    const rect = canvasRef.current?.getBoundingClientRect();
    const x = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const y = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const scaleX = (canvasRef.current?.width || 0) / (rect?.width || 1);
    const scaleY = (canvasRef.current?.height || 0) / (rect?.height || 1);
    ctx.current.lineTo((x - (rect?.left || 0)) * scaleX, (y - (rect?.top || 0)) * scaleY);
    ctx.current.stroke();
  };

  // 截取涂抹后的图片
  const downloadDaubedImage = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'daubed.png';
    link.click();
  };

  const clearDaub = () => {
    const canvas = canvasRef.current;
    const context = ctx.current;
    if (!canvas || !context) return;

    // 清除所有内容
    context.clearRect(0, 0, canvas.width, canvas.height);

    // 重新绘制背景图片
    const background = new Image();
    background.crossOrigin = 'anonymous';
    background.src = beijing;
    background.onload = () => {
      context.drawImage(background, 0, 0, canvas.width, canvas.height);
    };

    background.onerror = (error) => {
      console.error('Failed to load image:', error);
    };
  };

  return (
    <div className="drawContent">
      <button onClick={downloadDaubedImage}>下载涂抹后的图片</button>
      <button onClick={clearDaub}>清空涂抹</button>
      <div className="desc">
        <canvas
          ref={canvasRef}
          width="400"
          height="300"
          onMouseDown={startPosition}
          onMouseUp={finishedPosition}
          onMouseOut={finishedPosition}
          onMouseMove={draw}
          onTouchStart={startPosition}
          onTouchEnd={finishedPosition}
          onTouchCancel={finishedPosition}
          onTouchMove={draw}
        ></canvas>
      </div>
      <input
        type="range"
        min="1"
        max="100"
        value={zoomLevel}
        className="slider"
        onChange={e => setZoomLevel(parseInt(e.target.value))}
      /><span>{zoomLevel}%</span>
    </div>
  );
};

export default DaubTool;