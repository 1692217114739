import { useState } from "react";
import { Button, Image, ImageViewer } from 'antd-mobile'
import dayjs from 'dayjs';
import { DATE_FORMAT_SECOND } from '@/constants';
import "./index.less";

// 下载文件
const DownloadImage = ({ imgs }: any) => {
  const [visible, setVisible] = useState(false)
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imgs;
    link.download = `download_file_${dayjs().format(DATE_FORMAT_SECOND)}`;
    link.click();
  };

  return (
    <div className='downloadImageContent'>
      <Image className='img' src={imgs} width={'100%'} lazy fit='contain' onClick={() => setVisible(true)} />
      <ImageViewer
        classNames={{
          mask: 'customize-mask',
          body: 'customize-body',
        }}
        image={imgs}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
      <Button color='primary' className="downloadBtn" onClick={handleDownload}>下载</Button>
    </div>
  );
};
export default DownloadImage;
