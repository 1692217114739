export const STYLE_LIST_OPTIONS = [
  {
    label: '写实风格',
    value: 1,
    imgName: '1',
  },
  {
    label: '动漫风格',
    value: 2,
    imgName: '2',
  },
  {
    label: '3D风格',
    value: 3,
    imgName: '3',
  },
];

export const SIZE_LIST_OPTIONS = [
  {
    label: '1:1',
    value: 1,
  },
  {
    label: '3:4',
    value: 2,
  },
  {
    label: '4:3',
    value: 3,
  },
  {
    label: '9:16',
    value: 4,
  },
  {
    label: '16:9',
    value: 5,
  },
];

export const PROMPT_WORD_LENGTH = 200;
export const NEGATIVE_PROMPT_WORD_LENGTH = 200;

export const INSTRUCTIONS = [
  '· 天罡AI提供的文生图接口为Sdxl;',
  '· 付费会员使用限制为每人每天生成图片10张，禁止生成违法内容;',
  '· 文生图功能升级更新及使用限制等问题请留意公告通知;',
  '· 如有紧急需求或Al图文视频定制业务请联系400-001-8828;',
];