// import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TipsBox from '@/components/TipsBox';
import ImageModule from '@/components/ImageModule';
import Instructions from '@/components/Instructions';
import { STATIC_PATH } from '@/constants';
import 个性定制_二维码 from '@/assets/images/一级/个性定制_二维码.png';
import 个性定制_控形光影 from '@/assets/images/一级/个性定制_控形光影.png';
import 个性定制_明星合影 from '@/assets/images/一级/个性定制_明星合影.png';
import 个性定制_企业服务 from '@/assets/images/一级/个性定制_企业服务.png';
import 个性定制_科幻视频 from '@/assets/images/一级/个性定制_科幻视频.png';
import 个性定制_商业广告 from '@/assets/images/一级/个性定制_商业广告.png';
import 个性定制_娱乐视频 from '@/assets/images/一级/个性定制_娱乐视频.png';
import 个性定制_诗词视频 from '@/assets/images/一级/个性定制_诗词视频.png';
import 个性定制_企业级知识库定制 from '@/assets/images/一级/个性定制_企业级知识库定制.png';
import VideoLattice from '../VideoLattice';
import "./index.less";

// 个性定制
const Customiz = () => {

  const navigate = useNavigate();

  const toLink = (path) => {
    navigate(path);
  }


  return (
    <div className="customizContent">
      <TipsBox title='二维码定制' desc='个性化二维码私人定制' />
      <ImageModule imgs={个性定制_二维码} />
      <a className='entryBtn' onClick={() => toLink('appointment')}>进入预约</a>

      <TipsBox title='控形光影文字图形' desc='按需定制图片/海报' />
      <ImageModule imgs={个性定制_控形光影} />
      <a className='entryBtn' onClick={() => toLink('appointment')}>进入预约</a>

      <TipsBox title='明星合影' desc='AI虚拟合成 或 安排真实见面合影' />
      <ImageModule imgs={个性定制_明星合影} />
      <a className='entryBtn' onClick={() => toLink('appointment')}>进入预约</a>

      <TipsBox title='企业服务' desc='AI商业服务个性化定制' />
      <ImageModule imgs={个性定制_企业服务} />
      <a className='entryBtn' onClick={() => toLink('appointment')}>进入预约</a>

      <TipsBox title='' desc='平面设计/插图/图文' />
      <VideoLattice items={[{
        image: 个性定制_科幻视频,
        video: `${STATIC_PATH}video/一级/个性定制_科幻视频.mp4`,
        name: '科幻视频',
      }, {
        image: 个性定制_商业广告,
        video: `${STATIC_PATH}video/一级/个性定制_商业广告.mp4`,
        name: '商业广告',
      }, {
        image: 个性定制_娱乐视频,
        video: `${STATIC_PATH}video/一级/个性定制_娱乐视频.mp4`,
        name: '娱乐视频',
      }, {
        image: 个性定制_诗词视频,
        video: `${STATIC_PATH}video/一级/个性定制_诗词视频.mp4`,
        name: '诗词视频',
      }]} />

      <TipsBox title='企业级知识库定制' desc='' />
      <ImageModule imgs={个性定制_企业级知识库定制} />
      <a className='entryBtn' onClick={() => toLink('appointment')}>进入预约</a>

      <Instructions />
    </div>
  );
};
export default Customiz;
