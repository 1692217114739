// import { useState, useRef } from "react";
import './index.less';

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const DragSlider = ({ beforeImage, afterImage }) => {
  return (
    <div className='dragSliderContent'>
      <ReactCompareSlider
        onlyHandleDraggable={true}
        style={{ borderRadius: 30, touchAction: 'pan-y', border: '10px solid #fff' }}
        itemOne={<ReactCompareSliderImage srcSet={beforeImage} alt="beforeImage" />}
        itemTwo={<ReactCompareSliderImage srcSet={afterImage} alt="afterImage" />}
      />
    </div>

  );
};

export default DragSlider;