import { useState } from "react";
import { Image, ImageViewer } from 'antd-mobile';
import imageLoad from '@/assets/images/imageLoad.png';
import "./index.less";

const ImageModule = ({ imgs }: any) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className='imageModuleContent'>
      <ImageViewer
        classNames={{
          mask: 'customize-mask',
          body: 'customize-body',
        }}
        image={imgs}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
      <Image className='img' src={imgs} lazy fit='contain' onClick={() => setVisible(true)} fallback={imageLoad} />
    </div>
  );
};
export default ImageModule;
