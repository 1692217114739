export const PHOTOS_TYPE = [
  {
    type: '男士',
    images: [{
      name: '西装革履',
    }, {
      name: '商务男士',
    }, {
      name: '青春有你',
    }, {
      name: '风华年少',
    }, {
      name: '旅行',
    }, {
      name: '雅痞',
    }, {
      name: '极简衬衫',
    }, {
      name: '野蛮生长',
    }, {
      name: '大镖客',
    }, {
      name: '速度与激情',
    }],
  },
  {
    type: '古风',
    images: [{
      name: '国风毕业照1.',
    }, {
      name: '木兰辞',
    }, {
      name: '国风毕业照2',
    }, {
      name: '竹枝词',
    }, {
      name: '青青子衿',
    }, {
      name: '流年',
    }, {
      name: '半卷清辞',
    }, {
      name: '明月夜',
    }, {
      name: '南栀',
    }, {
      name: '青绾',
    }, {
      name: '一念簪花',
    }, {
      name: '青瓷',
    }, {
      name: '清归',
    }, {
      name: '云裳',
    }, {
      name: '国风毕业照',
    }],
  },
  {
    type: '文艺',
    images: [{
      name: '舞蝶',
    }, {
      name: '夏荷',
    }, {
      name: '秋风',
    }, {
      name: '秋语',
    }, {
      name: '浪漫花园',
    }, {
      name: '法式简约',
    }, {
      name: '光影拾秋',
    }, {
      name: '冬日恋歌',
    }, {
      name: '微风',
    }, {
      name: '日光',
    }, {
      name: '邂逅',
    }, {
      name: '初雪',
    }, {
      name: '氧气',
    }, {
      name: '银杏',
    }],
  },
  {
    type: '户外',
    images: [{
      name: '自然风光',
    }, {
      name: '温暖秋日',
    }, {
      name: '北欧穿行',
    }, {
      name: '街头艺术',
    }, {
      name: '沙丘',
    }, {
      name: '雪镜',
    }, {
      name: '雪山探险',
    }, {
      name: '海誓山盟',
    }, {
      name: '伦敦眼',
    }, {
      name: '漫游记',
    }, {
      name: '飘雪',
    }, {
      name: '思绪',
    }],
  },
  {
    type: '个性',
    images: [{
      name: '暗夜公主',
    }, {
      name: '美人鱼',
    }, {
      name: '幽灵乐园',
    }, {
      name: '通缉令',
    }, {
      name: '夜色狂欢',
    }, {
      name: '童话梦境',
    }, {
      name: '甜美公主',
    }, {
      name: 'MBTI紫人组',
    }, {
      name: '黑天使1',
    }, {
      name: '粉色甜心',
    }, {
      name: '青春记忆',
    }, {
      name: '在逃公主',
    }, {
      name: '婉约',
    }, {
      name: '美高校园',
    }, {
      name: '黑天使2',
    }, {
      name: '时尚都市',
    }],
  },
];