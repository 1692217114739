import { useState, useEffect } from "react";
import Masonry from 'react-responsive-masonry';
import ReactPlayer from 'react-player';
import { Tabs, Image } from 'antd-mobile';
import { isArray } from 'lodash-es';
import TipsBox from '@/components/TipsBox';
import { getMonoLayerList } from '@/utils';
import { VIDEO_TYPE } from '../../constants';

import imageLoad from '@/assets/images/imageLoad.png';

import "./index.less";
// 选择视频
const SelectVideo = ({ handleClick, handleSetSelectedVideoA }: any) => {
  const [videoList, setVideoList] = useState<any>([]);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>('');

  useEffect(() => {
    setVideoList(getMonoLayerList(VIDEO_TYPE, 'hot_video_list', ''));
  }, []);

  const playVideo = (video) => {
    setIsPlay(true);
    setVideoUrl(video);
  }

  const closeVideo = () => {
    setIsPlay(false);
  };

  return (
    <div className="selectVideoContent">
      <TipsBox title='从相册选择视频' desc='' />
      <a className='entryBtn' onClick={handleClick}>选择视频</a>
      <TipsBox title='or' desc='' />
      <TipsBox title='从以下视频模版选择' desc='' />
      {isArray(videoList) && videoList.length > 0 &&
        <Tabs defaultActiveKey='1' style={{ background: '#FFFFFF' }}>
          {videoList.map((item, index) =>
            <Tabs.Tab title={item.name} key={index + 1}>
              <Masonry columnsCount={2} gutter={10} className='videoList'>
                {isArray(item.photos) && item.photos.length > 0 && item.photos.map((cItem, cIndex) =>
                  <div className="itemBox" key={cIndex}>
                    <Image src={`${cItem}.png`} className="itemImage" onClick={() => playVideo(`${cItem}.mp4`)} fallback={imageLoad} />
                    <div className="toolbar">
                      <span onClick={() => handleSetSelectedVideoA(cItem)}>选择</span>
                    </div>
                  </div>
                )}
              </Masonry>
            </Tabs.Tab>
          )}
        </Tabs>
      }
      {isPlay &&
        <div className='videoModuleContent'>
          <div className='make' onClick={closeVideo}></div>
          <div className="playerContent">
            <ReactPlayer
              style={{ width: '100%', height: 'auto' }}
              url={videoUrl}
              playing
            />
          </div>
        </div>
      }
    </div >
  );
};
export default SelectVideo;
