import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import backImg from '@/assets/images/back.png';
import backImg3 from '@/assets/images/background3.jpg';
// import './index.less';

// 粒子背景
const ParticleBackground = () => {
  const [init, setInit] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  return (
    <div className='particleBackgroundContent'>
      {false && <img src={backImg} className='backImg' />}
      {init && (
        <Particles
          id='tsparticles'
          options={{
            fpsLimit: 120,
            background: {
              color: {
                value: '#CFDDFF',
              },
              image: `url(${backImg3})`,
              position: '50% 50%',
              repeat: 'no-repeat',
              size: 'cover',
              opacity: 0.5,
            },
            backgroundMask: {
              composite: 'destination-out',
              cover: {
                color: {
                  value: '#ffffff',
                },
                opacity: 0.9,
              },
              enable: true,
            },
            interactivity: {
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.5,
                },
              },
            },
            particles: {
              color: {
                value: 'random',
              },
              number: {
                density: {
                  enable: true,
                },
                value: 80,
              },
              opacity: {
                value: pathname === '/work' ? 1 : 0.1,
              },
              shape: {
                // type: "circle",
                close: true,
                fill: true,
                options: {},
                type: 'circle',
              },
              size: {
                value: { min: 60, max: 100 },
              },
              effect: {
                close: true,
                fill: true,
                options: {},
              },
              move: {
                direction: 'top',
                drift: 0,
                enable: true,
                random: true,
                size: false,
                speed: 1,
                straight: true,
                vibrate: false,
                warp: false,
              },
              pauseOnBlur: true,
            },
            detectRetina: true,
          }}
          className='backImg'
        />
      )}
    </div>
  );
};
export default ParticleBackground;
